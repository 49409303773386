import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { useState } from "react";
import { useDataProvider } from "react-admin";

import { EventTypes } from "../../../utils/consts";
import { EventLogNotification } from "../../Notification";

function hideRow(id, event_type, plantId, setVisible, dataProvider, user) {
  dataProvider
    .hideEvent(id, plantId, user, { event_type, target: "notification" })
    .then(() => {
      setVisible(false);
    });
}

export function getSeverity(entry) {
  let severity = "info";
  const title = Object.entries(EventTypes).find(([key, val]) =>
    val.includes(entry.event_type)
  )[0];
  if (entry.status === "OK") {
    severity = "success";
  }
  else {
    if (entry.event_type !== "Alarma Activa") {
      severity = "warning";
    }
    else {
      severity = "error";
    }
  }
  return severity
}

const LogRow = ({
  entry,
  plantId,
  viewHidden,
  filteredLogTypes,
  fromTime,
  toTime,
  user,
}) => {
  const [visible, setVisible] = useState(entry.visible);

  const dataProvider = useDataProvider();

  const handleClose = () => {
    hideRow(
      entry._id,
      entry.event_type,
      plantId,
      setVisible,
      dataProvider,
      user
    );
  };

  if (
    (!entry.visible & !viewHidden) |
    (!visible & !viewHidden) |
    !filteredLogTypes.includes(entry.event_type) |
    (dayjs(entry.timestamp) < fromTime) |
    (dayjs(entry.timestamp) > toTime)
  ) {
    return null;
  }
  const title = `Actualizado:  ${new Date(entry.worker_timestamp)
    .toLocaleString("es-cl")
    .replace(", ", " ")}`;
    return (
    <Tooltip title={title}>
      <EventLogNotification event={entry} handleClose={handleClose} />
    </Tooltip>
  );
};

export default LogRow;
