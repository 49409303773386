import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useDataProvider } from "react-admin";

function hideRows(plantId, dataProvider, fromTime, toTime, setOpen) {
  dataProvider.hideEvents(plantId, fromTime, toTime).then(() => setOpen(false));
}

const ArchiveDialog = ({
  events,
  open,
  setOpen,
  plantId,
  fromTime,
  toTime,
}) => {
  const dataProvider = useDataProvider();

  const handleConfirm = () => {
    hideRows(plantId, dataProvider, fromTime, toTime, setOpen);
    window.location.reload();
    // setEvents(
    //   events.map((event) => {
    //     event.visible = false;
    //     return event;
    //   })
    // );
  };
  const visibleEvents = events.filter((event) => event.visible == true);
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="sm"
      open={open}
    >
      {visibleEvents.length > 0 ? (
        <>
          <DialogTitle>
            <Typography variant="h4" align="center" sx={{ mt: 2 }}>
              Archivar Todos
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6" align="left">
              Está seguro que desea archivar todos los eventos?
            </Typography>
            <Typography variant="h6" align="left">
              Esta acción no se puede deshacer.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color="error"
              variant={"filled"}
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
            <Button color="success" variant={"filled"} onClick={handleConfirm}>
              Ok
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <Typography variant="h4" align="center" sx={{ mt: 2 }}>
            Archivar Todos
          </Typography>
          <DialogContent>
            <Typography variant="h6" align="left">
              No hay eventos por archivar en las fechas ingresadas.
            </Typography>
            <Typography variant="h6" align="left">
              Intente con otro rango de fechas.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color="error"
              variant={"outlined"}
              onClick={() => setOpen(false)}
            >
              Cerrar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default memo(ArchiveDialog);
