import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useState } from "react";
import { useGetIdentity } from "react-admin";

import Info from "../../../components/Info";
import Loading from "../../../components/Loading";
import LogRow from "./Row";

const LogTable = ({
  events,
  plantId,
  viewHidden,
  filteredLogTypes,
  fromTime,
  toTime,
}) => {
  const [user, setUser] = useState(null);

  const identity = useGetIdentity();
  useEffect(() => {
    if (!identity.isLoading) {
      setUser(identity.data.fullName);
    }
  }, [identity]);

  if (!filteredLogTypes) {
    if (events.length) {
      return <Loading text="Cargando..." />;
    }
    return <Info text="No hay eventos para el rango de fechas seleccionado." />;
  }
  if (!events.length) {
    return <Info text="No hay eventos para el rango de fechas seleccionado." />;
  }

  const visibleEvents = events.filter((event) => event.visible == true);
  if (!visibleEvents.length > 0 && viewHidden == false) {
    return <Info text="No hay eventos para el rango de fechas seleccionado." />;
  }

  const entries = events.map((event) => {
    return (
      <LogRow
        key={event._id}
        entry={event}
        filteredLogTypes={filteredLogTypes}
        plantId={plantId}
        fromTime={fromTime}
        toTime={toTime}
        viewHidden={viewHidden}
        user={user}
      />
    );
  });

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        py: 1,
        borderRadius: 1,
      }}
    >
      {entries}
    </Stack>
  );
};

export default LogTable;
