import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { divIcon, point } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useMemo } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import "../../styles.css";
import {
  backgroundColors,
  mapMarkerIcons,
  stateIcons,
  statusIcons,
} from "../../utils/consts";
import Loading from "../Loading";

const ZOOM_OUT = 10;
const ZOOM_IN = 13;

const createClusterMarker = (cluster) => {
  const getState = (states) => {
    switch (true) {
      case states.has("Error"):
        return "cluster-icon-error";
      case states.has("Warning"):
        return "cluster-icon-warning";
      case states.has("OK"):
        return "cluster-icon-ok";
      case states.has("Unavailable"):
        return "cluster-icon-unavailable";
      default:
        return "cluster-icon-unavailable";
    }
  };
  const className = getState(
    new Set(cluster.getAllChildMarkers().map((marker) => marker.options.status))
  );
  return new divIcon({
    html: `<div class=${className}>${cluster.getChildCount()}</div>`,
    className: "custom-marker-cluster",
    iconSize: point(100, 100, true),
  });
};

function initializeMapData(plants, selectedPlantId) {
  const mapData = {};
  if (selectedPlantId == 0) {
    mapData.center = plants
      .reduce(
        (sum, plant) => [sum[0] + plant.coords[0], sum[1] + plant.coords[1]],
        [0, 0]
      )
      .map((coord) => coord / plants.length);
    mapData.zoom = ZOOM_OUT;
  } else {
    const selectedPlant = plants.filter(
      (plant) => plant.id == selectedPlantId
    )[0];
    mapData.center = selectedPlant.coords;
    mapData.zoom = ZOOM_IN;
  }
  return mapData;
}

const RecenterMap = ({ center, zoom, setMapData }) => {
  const map = useMap();
  useEffect(() => {
    map.flyTo(center, zoom, { duration: 1 });
    setMapData({ center, zoom });
  }, [center, zoom]);
  return null;
};

const StateMarker = ({ state }) => {
  return (
    <Tooltip title={`${state.name}`}>
      <Box
        sx={{
          borderRadius: 1,
          p: 1,
          display: "flex",
          justify: "center",
          backgroundColor: backgroundColors[state.value.STATUS],
        }}
      >
        {stateIcons[state.name][state.value.STATUS]}
      </Box>
    </Tooltip>
  );
};

const SingleMarker = ({
  plant,
  markerRefs,
  setSelectedPlantId,
  setExpanded,
}) => {
  const status = plant.active ? plant.overall_status : "Unavailable";
  const icon = plant.active
    ? mapMarkerIcons[status]
    : mapMarkerIcons.Unavailable;
  return (
    <Marker
      key={plant.id}
      ref={(element) => (markerRefs[plant.id] = element)}
      position={plant.coords}
      plantId={plant.id}
      status={status}
      icon={icon}
      eventHandlers={{
        click: (e) => {
          if (plant.active && e.target.options) {
            const plantId = e.target.options.plantId;
            setSelectedPlantId(plantId);
            setExpanded(plantId);
          }
        },
      }}
    >
      <Popup className="overview-popup">
        <Box
          sx={{
            py: 2,
          }}
        >
          <Grid container direction="column" spacing={2}>
            <Grid container item direction="row" spacing={1}>
              <Stack direction="row" spacing={1}>
                <Tooltip title={`${plant.overall_status}`}>
                  {statusIcons[plant.overall_status]}
                </Tooltip>
                <Typography variant="subtitle1">{plant.name}</Typography>
              </Stack>
            </Grid>
            <Grid container item direction="row" justifyContent="space-evenly">
              {plant.states.map((state) => (
                <StateMarker key={state.name} state={state} />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Popup>
    </Marker>
  );
};

const Markers = (props) => {
  const { plants, setSelectedPlantId, setExpanded } = props;
  const markerRefs = useRef([]);

  return useMemo(
    () =>
      plants.map((plant) => (
        <SingleMarker
          key={plant.id}
          plant={plant}
          markerRefs={markerRefs}
          setSelectedPlantId={setSelectedPlantId}
          setExpanded={setExpanded}
        />
      )),
    [plants]
  );
};

const PlantsOverviewMap = ({
  plants,
  selectedPlantId,
  setSelectedPlantId,
  setExpanded,
  mapData,
  setMapData,
}) => {
  const accessToken = window["runtimeConfig"].REACT_APP_MAP_ACCESS_TOKEN;
  const url = `https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=${accessToken}`;
  useEffect(() => {
    const activePlants = plants.filter((plant) => plant.active == true);
    const candidateMapData = initializeMapData(activePlants, selectedPlantId);
    if (JSON.stringify(mapData) !== JSON.stringify(candidateMapData)) {
      // Avoid triggering infinite re-render
      setMapData(candidateMapData);
    }
  });

  if (mapData == null) {
    return <Loading />;
  }
  return (
    <MapContainer {...mapData}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url={url} //'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png' -> darkTheme
      />
      <MarkerClusterGroup
        chunkedLoading
        iconCreateFunction={createClusterMarker}
        showCoverageOnHover={false}
      >
        <Markers
          plants={plants}
          setSelectedPlantId={setSelectedPlantId}
          setExpanded={setExpanded}
        />
        <RecenterMap {...mapData} setMapData={setMapData} />
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default PlantsOverviewMap;
