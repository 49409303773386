import Grid from "@mui/material/Grid";
import { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Loading from "../../../components/Loading";
import { EventTypes } from "../../../utils/consts";
import LogHeader from "./Header";
import LogTable from "./Table";

const EventLog = ({
  events,
  activeCardId,
  plantId,
  fromTime,
  toTime,
  availableLogTypes,
  setAvailableLogTypes,
  filteredLogTypes,
  setFilteredLogTypes,
}) => {
  const [viewHidden, setViewHidden] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const ref = useRef(null);
  const [tableHeight, setTableHeight] = useState("80vh");

  const filterLogType = searchParams.get("filterLogType");

  useEffect(() => {
    function handleWindowResize() {
      if (ref !== null) {
        setTableHeight(`calc(85vh - ${ref.current.clientHeight}px)`);
      }
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const incomingLogTypes =
    events !== null
      ? Array.from(new Set(events.map((event) => event.event_type)))
      : null;

  useEffect(() => {
    if (availableLogTypes) {
      availableLogTypes.sort();
    }
    if (incomingLogTypes) {
      incomingLogTypes.sort();
    }
    const incomingFilter = EventTypes[filterLogType];
    if (incomingFilter !== undefined) {
      setFilteredLogTypes(incomingFilter);
      searchParams.delete("filterLogType");
      return;
    }
    if (
      events !== null &&
      JSON.stringify(availableLogTypes) !== JSON.stringify(incomingLogTypes)
    ) {
      setAvailableLogTypes(incomingLogTypes);
      if (!filteredLogTypes) {
        setFilteredLogTypes(incomingLogTypes);
      }
    }
  }, [events, availableLogTypes, incomingLogTypes, filterLogType]);
  if (!availableLogTypes && !filteredLogTypes) {
    return <Loading />;
  }

  return (
    <Grid
      container
      sx={{
        height: "100%",
        width: "100%",
        px: 3,
        py: 1,
        backgroundColor: "background.dark",
      }}
    >
      <Grid
        item
        sx={{
          width: "100%",
          mb: 1,
        }}
      >
        <LogHeader
          ref={ref}
          events={events}
          plantId={plantId}
          activeCardId={activeCardId}
          availableLogTypes={availableLogTypes}
          viewHidden={viewHidden}
          setViewHidden={setViewHidden}
          filteredLogTypes={filteredLogTypes}
          setFilteredLogTypes={setFilteredLogTypes}
          fromTime={fromTime}
          toTime={toTime}
        />
      </Grid>
      <Grid
        item
        sx={{
          height: tableHeight,
          width: "100%",
          overflow: "auto",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <LogTable
          events={events}
          plantId={plantId}
          viewHidden={viewHidden}
          filteredLogTypes={filteredLogTypes}
          setFilteredLogTypes={setFilteredLogTypes}
          fromTime={fromTime}
          toTime={toTime}
        />
      </Grid>
    </Grid>
  );
};

export default EventLog;
