import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import chroma from "chroma-js";
import { useState } from "react";

import { COLORRANGE } from "../../utils/consts";

const COLORRANGE_LENGTH = COLORRANGE.length;
const MIN_DELTA = 1;

const ScaleSelector = ({ scaleValue, setScaleValue, max }) => {
  const handleChange = (value) => {
    setScaleValue(value);
  };
  return (
    <TextField
      variant="outlined"
      label={max ? "Máximo" : "Mínimo"}
      value={scaleValue}
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      onKeyDown={(e) => {
        if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        handleChange(e.target.value);
      }}
    />
  );
};

export const ColorScaleHeader = ({
  colorScaleValues,
  setColorScaleValues,
  colorRange,
}) => {
  const [min, setMin] = useState(
    colorScaleValues == null ? null : colorScaleValues[0]
  );
  const [max, setMax] = useState(
    colorScaleValues == null ? null : colorScaleValues[1]
  );

  const range = [...Array(COLORRANGE_LENGTH).keys()].map(
    (point) => point + min
  );
  const colorscale = chroma.scale(colorRange).domain(range); //Get n colors programatically?
  const colors = range.map((point) => colorscale(point).hex());

  return (
    <Grid
      container
      direction="row"
      sx={{
        height: "60px",
        justifyContent: "center",
        alignItems: "center",
        px: 3,
      }}
    >
      <Grid
        item
        sx={{
          width: "65px",
          alignItems: "center",
          justifyContent: "center",
          mx: 1,
        }}
      >
        <ScaleSelector scaleValue={min} setScaleValue={setMin} max={false} />
      </Grid>
      {colors.map((color, index) => {
        return (
          <Grid
            key={index}
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              height: "75%",
              backgroundColor: color,
              borderRadius: 1,
              mx: 0.125,
            }}
          />
        );
      })}
      <Grid
        item
        sx={{
          width: "65px",
          alignItems: "center",
          justifyContent: "center",
          mx: 1,
        }}
      >
        <ScaleSelector scaleValue={max} setScaleValue={setMax} max={true} />
      </Grid>
      <Grid
        item
        sx={{
          height: "75%",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            const newValues = [
              Math.max(0, min),
              Math.min(100, Math.max(...[max, min + MIN_DELTA])),
            ];
            setColorScaleValues(newValues);
            setMin(newValues[0]);
            setMax(newValues[1]);
          }}
        >
          Ajustar
        </Button>
      </Grid>
    </Grid>
  );
};

export default ColorScaleHeader;
