import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import chroma from "chroma-js";
import Plot from "react-plotly.js";

import { COLORRANGE } from "../../utils/consts";

const SUBPLOT_COLOR = "black";
const ALERT_COLOR = COLORRANGE[COLORRANGE.length - 6];
const ALARM_COLOR = COLORRANGE[COLORRANGE.length - 1];

const BASE_PROPS = {
  style: { width: "100%" },
  useResizeHandler: true,
};

export function buildColorScale(colorScale, colorRange) {
  const [min, max] = colorScale;
  const points = [...Array(max - min).keys()].map((point) => point + min);
  const colorscale = chroma.scale(colorRange).domain(points); //Get n colors programatically?
  return colorscale;
}

function buildTraces(zones, traces) {
  const allTracesData = Object.keys(traces).map((zoneId, index) =>
    buildSingleTraceLayout(
      traces[zoneId],
      zones.filter((zone) => zone.id == zoneId)[0],
      index + 1
    )
  );
  return allTracesData;
}

function buildSingleTraceLayout(trace, zone, plotIdx) {
  const samplingInterval = trace.sampling_interval;
  const x = Array.from(
    { length: trace.TEMP_DATA.length },
    (_, index) => samplingInterval * index
  );
  const y = trace.TEMP_DATA;
  const colorscale = buildColorScale(
    [
      0, 70,
      // parseInt(trace.MIN_TEMP),
      // Math.max(parseInt(zone.alarm_threshold), parseInt(trace.MAX_TEMP)),
    ],
    COLORRANGE
  );
  const markerColors = y.map((value) => colorscale(value).hex());
  const lineColor = colorscale(y.reduce((a, b) => a + b) / y.length).hex();
  return {
    data: [
      {
        hovertemplate: "Metro %{x}: %{y:.2f}°C <extra></extra>",
        hoverlabel: {
          font: {
            size: 28, // Increase this value to enlarge the hover text size
          },
        },
        x: x,
        y: y,
        xaxis: `x${plotIdx}`,
        yaxis: `y${plotIdx}`,
        type: "scatter",
        mode: "lines+markers",
        marker: {
          color: markerColors,
          size: 3,
        },
        line: {
          shape: "spline",
          color: lineColor,
          size: 3,
        },
        name: zone.name,
      },
      {
        hovertemplate: "Umbral Alarma: %{y:.0f} °C <extra></extra>",
        x: x,
        y: Array(x.length).fill(zone.alarm_threshold),
        xaxis: `x${plotIdx}`,
        yaxis: `y${plotIdx}`,
        type: "scatter",
        mode: "lines",
        hoverlabel: {
          font: {
            size: 28, // Increase this value to enlarge the hover text size
          },
        },
        line: {
          dash: "dash",
          shape: "spline",
          color: ALARM_COLOR,
          size: 1,
        },
        name: "Alarma",
      },
      {
        hovertemplate: "Umbral Pre-Alarma: %{y:.0f} °C <extra></extra>",
        x: x,
        y: Array(x.length).fill(zone.pre_alarm_threshold),
        xaxis: `x${plotIdx}`,
        yaxis: `y${plotIdx}`,
        type: "scatter",
        mode: "lines",
        line: {
          dash: "dash",
          shape: "spline",
          color: ALERT_COLOR,
          size: 1,
        },
        name: "Pre-Alarma",
        hoverlabel: {
          font: {
            size: 28, // Increase this value to enlarge the hover text size
          },
        },
      },
    ],
    annotations: [
      {
        text: zone.name,
        font: {
          size: 16,
          color: SUBPLOT_COLOR,
        },
        showarrow: false,
        align: "center",
        x: 0.5,
        y: 1,
        xref: `x${plotIdx} domain`,
        yref: `y${plotIdx} domain`,
        yanchor: `bottom`,
      },
    ],
    domain: {
      x: [0, x.length * samplingInterval],
      y: [
        Math.min(0, parseInt(trace.MIN_TEMP) - 10),
        parseInt(zone.alarm_threshold) + 10,
      ],
    },
  };
}

const ZoneTracesPlot = (props) => {
  const { traces, zones } = props;
  if (zones.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ErrorOutlineIcon fontSize="large" color="warning" />
        <Typography color="warning" variant="h5">
          {"No hay zonas seleccionadas."}
        </Typography>
      </Box>
    );
  }

  const tracesData = buildTraces(zones, traces);
  const data = tracesData.map((trace) => trace.data).flat();
  const annotations = tracesData.map((trace) => trace.annotations).flat();
  const axisValues = tracesData
    .map((trace, index) => [
      {
        [`xaxis${index + 1}`]: {
          range: trace.domain.x,
          title: {
            text: "Fibra [m]",
            standoff: 0,
          },
          titlefont: {
            color: SUBPLOT_COLOR,
          },
          tickfont: {
            color: SUBPLOT_COLOR,
          },
          zerolinecolor: "rgba(0,0,0,0)",
          zerolinewidth: 0,
          gridcolor: "rgba(0,0,0,0)",
          gridwidth: 0,
          linecolor: SUBPLOT_COLOR,
          linewidth: 1,
          tickcolor: SUBPLOT_COLOR,
          showspikes: true,
          spikemode: "across",
          spikedash: "dash",
          spikecolor: "white",
          spikethickness: -2,
        },
        [`yaxis${index + 1}`]: {
          range: trace.domain.y,
          title: {
            text: "T [°C]",
            standoff: 0,
          },
          titlefont: {
            color: SUBPLOT_COLOR,
          },
          tickfont: {
            color: SUBPLOT_COLOR,
          },
          zerolinecolor: "rgba(0,0,0,0)",
          zerolinewidth: 0,
          gridcolor: "rgba(0,0,0,0)",
          gridwidth: 0,
          linecolor: SUBPLOT_COLOR,
          linewidth: 1,
          tickcolor: SUBPLOT_COLOR,
        },
      },
    ])
    .flat()
    .flat();
  const axis = axisValues.reduce((acc, obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      acc[key] = value;
    });
    return acc;
  }, {});

  const layout = {
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    // autosize: false,
    height: 300 * zones.length,
    showlegend: false,
    grid: {
      rows: zones.length,
      columns: 1,
      pattern: "independent",
    },
    annotations: annotations,
    spikedistance: 200,
    hoverdistance: 10,
    ...axis,
  };

  const config = {
    displayModeBar: false,
    responsive: true,
  };
  return <Plot data={data} layout={layout} config={config} {...BASE_PROPS} />;
};

export default ZoneTracesPlot;
