import Box from "@mui/material/Box";

import ZoneTracesPlot from "../ZoneTrace";

const RealTimeTracesDisplay = ({ zoneIds, zones, traces }) => {
  const filteredTraceValues = Object.entries(traces).filter((key, value) =>
    zoneIds.includes(parseInt(key))
  );
  const filteredTraces = Object.fromEntries(filteredTraceValues);
  const filteredZones = zones.filter((zone) => zoneIds.includes(zone.id));

  return (
    <Box
      sx={{
        justifyContent: "center",
        backgroundColor: "background.dark",
        alignItems: "center",
        height: "calc(92vh - 52px)",
        width: "100%",
        overflow: "hidden",
        overflowY: "scroll",
        overflow: "auto",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <ZoneTracesPlot zones={filteredZones} traces={filteredTraces} />
    </Box>
  );
};

export default RealTimeTracesDisplay;
