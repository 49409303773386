import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

const EventTypeFilter = ({
  availableLogTypes,
  filteredLogTypes,
  setFilteredLogTypes,
}) => {
  const onChange = (event, newValue) => {
    const newFilteredLogTypes = [
      ...filteredLogTypes,
      ...newValue.filter((option) => filteredLogTypes.indexOf(option) == -1),
    ];
    if (
      JSON.stringify(filteredLogTypes) !== JSON.stringify(newFilteredLogTypes)
    ) {
      setFilteredLogTypes(newFilteredLogTypes);
    }
  };

  const renderTag = (option) => {
    const onDelete = () => {
      if (filteredLogTypes.includes(option)) {
        setFilteredLogTypes(filteredLogTypes.filter((x) => x != option));
      }
    };
    return (
      <Chip
        key={option}
        label={option}
        onDelete={onDelete}
        sx={{
          backgroundColor: "primary",
          m: 0.25,
        }}
      />
    );
  };

  const renderTags = (tagValues, getTagProps) => {
    const numTags = tagValues.length;
    const limit = 3;
    const sortedTags = tagValues.sort((a, b) => a.length - b.length);
    return (
      <>
        {sortedTags.slice(0,limit).map(renderTag)}
        {numTags > limit && ` +${numTags - limit} más...`}
      </>
      )
  };
  const renderInput = (params) => {
    return <TextField label="Tipo de Evento" {...params} />;
  };
  return (
    <Autocomplete
      ListboxProps={{ style: { maxHeight: 100 } }}
      multiple
      value={filteredLogTypes || []}
      onChange={onChange}
      options={availableLogTypes || []}
      getOptionLabel={(option) => option}
      renderTags={renderTags}
      renderInput={renderInput}
    />
  );
};

export default EventTypeFilter;
