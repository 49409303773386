import DataFrame from "dataframe-js";
import { useMemo } from "react";
import Plot from "react-plotly.js";

import { getMinMax } from "../../utils/dataTransformation";
import { interpolateAndGroup } from "../../utils/dataTransformation";
import "./hide.css";

const DEACTIVATED_COLOR = "#66666666";

const BASE_PROPS = {
  style: { width: "100%", height: "100%" },
  useResizeHandler: true,
  uirevision: 99,
  config: {
    displayModeBar: false,
    // doubleClick: 'reset',
    doubleClick: "reset",
    scrollZoom: false,
    responsive: true,
    // staticPlot: true,
    // doubleClick: 'autosize',
    // doubleClick: 'reset+autosize',
  },
  // useResizeHandler: true,
  /*
  Note: To make a plot responsive, i.e. to fill its containing element and resize
  when the window is resized, use style or className to set the dimensions of the
  element (i.e. using width: 100%; height: 100% or some similar values) and set
  useResizeHandler to true while setting layout.autosize to true and leaving
  layout.height and layout.width undefined. This can be seen in action in this
  CodePen and will implement the behaviour documented here:
  
  https://plot.ly/javascript/responsive-fluid-layout/
  
  */
  onRelayout: () => console.warn("reLayout"),
  // ...xFilterUtils.handlers
};

const lowerPlotHeightProportion = 0.0;
const plotSpacingProportion = 0.0;
const INVISIBLE_AXIS = {
  visible: false,
  showgrid: false,
  showline: false,
};

function initializeDf(verticesPerZone, tempsPerZone) {
  const colNames = [
    "fiberTraceIdx",
    "zoneId",
    "temp",
    "x",
    "y",
    "anchorIdx",
    "isInterpolated",
    "tempIndex",
    "hovered",
  ];
  const rows = [];
  const datetime = new Date(
    Math.max(
      ...Object.entries(tempsPerZone)
        .map((a) => a[1].TIME_STAMP)
        .map((t) => new Date(t))
    )
  );
  for (const [zoneId, vertices] of Object.entries(verticesPerZone)) {
    let temps;
    try {
      temps = tempsPerZone[zoneId].TEMP_DATA;
    } catch (error) {
      continue;
    }
    for (const [fiberTraceIdx, group] of Object.entries(
      interpolateAndGroup(
        vertices.traces,
        temps,
        vertices.start_alpha,
        vertices.end_alpha
      )
    )) {
      const avg = 0.5 * (group[0].temp + group[group.length - 1].temp);
      for (const [inGroupIdx, vertex] of Object.entries(group)) {
        rows.push([
          fiberTraceIdx,
          zoneId,
          vertex.temp == undefined ? avg : vertex.temp,
          vertex.x,
          vertex.y,
          vertex.anchorIdx,
          vertex.isInterpolated,
          vertex.tempIndex,
          false,
        ]);
      }
    }
  }
  return { df: new DataFrame(rows, colNames), datetime: datetime };
}

function buildMapPlotDataForSingleGroup(df, zone, imageDimensions) {
  const rows = df.toDict();
  const temps = rows.temp;

  return {
    name: zone.name,
    kind: "map",
    zoneId: zone.id,
    type: "scattergl",
    mode: "lines+markers",
    x: rows.x.map((x) => x * imageDimensions.width),
    y: rows.y.map((x) => x * imageDimensions.height),
    hoverinfo:'none',
    axis: "x",
    yaxis: "y",
    marker: {
      color: DEACTIVATED_COLOR, // defineColor(group, currentSelectMask, lineColor),
      line: {
        color: "transparent", // Set the marker border color to transparent
      },
      // size: defineSize(group, currentSelectMask, currentHoverMask, hoverLast),
      // size: defineMapMarkerSize(group, hover, zone),
      size: rows.isInterpolated.map((x) => (x !== true ? 0 : 4)),
    },
    line: {
      // color: LINE_COLOR /*currentSelectMask
      color: DEACTIVATED_COLOR /*currentSelectMask
        ? currentHoverMask
          ? lineColor.brighten().hex()
          : lineColor.hex()
        : lineColor.alpha(0.3).hex()*/,
      width: 2,
    },
    showlegend: false,
    showscale: false,
    indexes: rows.tempIndex,
    temps: temps,
    name: zone.name,
    hoverlabel: {
      font: {
        size: 28, // Increase this value to enlarge the hover text size
      },
    },
    // uirevision: zone.id,
    // datarevision: zone.id,
  };
}

function colorLayout(data, colorscale) {
  function colorSingleZone(zoneData) {
    const avg =
      0.5 * (zoneData.temps[0] + zoneData.temps[zoneData.temps.length - 1]);
    const lineColor = colorscale(avg).hex();
    const markerColors = zoneData.temps.map((t) => colorscale(t).hex());
    const markerSize = 4;
    const lineWidth = 1;
    return {
      ...zoneData,
      customdata: zoneData.temps.map((temp) => [temp, zoneData.name]),
      hovertemplate:
        "%{customdata[1]}: <b>%{customdata[0]:.2f}°C</b> <extra></extra>",
      marker: {
        symbol: "square",
        color: markerColors,
        line: {
          color: "transparent",
        },
        size: zoneData.marker.size.map((x) => (x == 0 ? 0 : markerSize)),
      },
      line: {
        color: lineColor,
        width: lineWidth,
      },
    };
  }
  return data.map((zoneData) => colorSingleZone(zoneData));
}

function buildLayout(mapsBox, imageUrl, imageDimensions, shapes) {
  const commonLayout = {
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    showlegend: false,
    title: {
      font: {
        family: "Courier New, monospace",
        size: 24,
        color: "#ffffffff",
      },
      x: 0.995,
      y: 0.01,
      xref: "x",
      yref: "y",
    },
    shapes: shapes,
  };
  const mapLayout = {
    xaxis: {
      kind: "map",
      fixedrange: true,
      range: [0, imageDimensions.width],
      // range: mapMarginX(...mapsBox.x),
      ...INVISIBLE_AXIS,
      scaleanchor: "y",
    },
    yaxis: {
      kind: "map",
      fixedrange: true,
      range: [0, imageDimensions.height],
      // range: mapMarginY(...mapsBox.y),
      domain: [lowerPlotHeightProportion + plotSpacingProportion, 1],
      ...INVISIBLE_AXIS,
    },
    images: [
      {
        source: imageUrl,
        x: 0,
        y: 0,
        xref: "x",
        yref: "y",
        xanchor: "left",
        yanchor: "bottom",
        sizex: imageDimensions.width,
        sizey: imageDimensions.height,
        sizing: "stretch",
        layer: "below",
      },
    ],
  };
  return {
    margin: { t: 0, r: 0, b: 0, l: 0 },
    uirevision: true,
    ...commonLayout,
    ...mapLayout,
  };
}

class Box {
  constructor(xv, yv) {
    this.x = [Infinity, -Infinity];
    this.y = [Infinity, -Infinity];
    if (xv != undefined && yv != undefined) {
      this.update({
        x: getMinMax(xv),
        y: getMinMax(yv),
      });
    }
  }

  update(incoming) {
    for (const axis of ["x", "y"]) {
      for (const [pos, extremum] of Object.entries({
        0: Math.min,
        1: Math.max,
      })) {
        this[axis][pos] = extremum(this[axis][pos], incoming[axis][pos]);
      }
    }
  }
}

function buildPlotly(
  df,
  zones,
  zoneIds,
  imageUrl,
  imageDimensions,
  colorscale
) {
  const plotParams = {};
  const plots = [];
  const mapsBox = new Box();
  const mapsBoxData = [];
  const zonesGroupBy = df.groupBy("zoneId");

  const shapes = [];
  for (const { group: zoneGroup, groupKey: zoneGroupKey } of zonesGroupBy) {
    const zoneId = zoneGroupKey.zoneId;
    const zone = zones.filter((z) => z.id == zoneId)[0];

    const mapPlot = buildMapPlotDataForSingleGroup(
      zoneGroup,
      zone,
      imageDimensions,
      zoneIds
        ? zoneIds.includes(parseInt(zoneId))
          ? colorscale
          : null
        : colorscale
    );
    plots.push(mapPlot);
    mapsBoxData.push([mapPlot.x, mapPlot.y]);
  }
  mapsBox.update(
    new Box(
      mapsBoxData.map(([x, y], _) => x).flat(),
      mapsBoxData.map(([x, y], _) => y).flat()
    )
  );
  plotParams.data = plots;
  plotParams.layout = buildLayout(mapsBox, imageUrl, imageDimensions, shapes);
  return plotParams;
}

const MyPlot = ({ zoneIds, setZoneIds, ...props }) => {
  function handleUpdateZoneIds(zoneId, zoneIds, setZoneIds) {
    if (zoneIds.includes(zoneId)) {
      const newZoneIds = zoneIds.filter((zId) => zId !== zoneId);
      setZoneIds(newZoneIds);
      return;
    }
    setZoneIds([...zoneIds, zoneId]);
  }
  return (
    <>
      <Plot
        onClick={(e) =>
          handleUpdateZoneIds(e.points[0].data.zoneId, zoneIds, setZoneIds)
        }
        {...props}
      />
    </>
  );
};

export const MapAndTempsFromFrame = ({
  df,
  zoneIds,
  zones,
  setZoneIds,
  imageUrl,
  imageDimensions,
  datetime,
  colorscale,
}) => {
  const { data, layout } = useMemo(
    () =>
      buildPlotly(df, zones, zoneIds, imageUrl, imageDimensions, colorscale),
    [df, zones, imageUrl, imageDimensions, colorscale]
  );

  const coloredData = useMemo(
    () => colorLayout(data, colorscale),
    [data, colorscale]
  );
  const filteredData = [
    ...coloredData.filter((zone) => zoneIds.includes(zone.zoneId)),
    ...data.filter((zone) => !zoneIds.includes(zone.zoneId)),
  ];
  // const [traceUpdate, setTraceUpdate] = useContext(TraceUpdateContext);
  // const updateTimestamp = datetime.toLocaleString("es-cl");
  // useEffect(() => {
  //   setTraceUpdate(updateTimestamp);
  // }, [updateTimestamp]);
  return (
    <MyPlot
      data={filteredData}
      layout={layout}
      zoneIds={zoneIds}
      setZoneIds={setZoneIds}
      {...BASE_PROPS}
    />
  );
};

export const MapAndTemps = ({
  verticesPerZone,
  tempsPerZone,
  zones,
  zoneIds,
  setZoneIds,
  colorscale,
  imageUrl,
  imageDimensions,
}) => {
  console.debug("[MapAndTemps] verticesPerZone: ", verticesPerZone);
  console.debug("[MapAndTemps] tempsPerZone: ", tempsPerZone);
  console.debug("[MapAndTemps] zones: ", zones);
  const t0 = performance.now();
  const { df, datetime } = useMemo(
    () => initializeDf(verticesPerZone, tempsPerZone),
    [verticesPerZone, tempsPerZone] // maybe we could change this?
  );
  console.info(
    "[MapAndTemps: initializeDf] execution time: ",
    performance.now() - t0,
    "ms"
  );
  return (
    <MapAndTempsFromFrame
      df={df}
      zoneIds={zoneIds}
      zones={zones}
      setZoneIds={setZoneIds}
      imageUrl={imageUrl}
      imageDimensions={imageDimensions}
      datetime={datetime}
      colorscale={colorscale}
    />
  );
};
