import { stringify } from "query-string";
import { fetchUtils } from "react-admin";

import { getBearerToken } from "./authProvider";
import { groupBy } from "./ext";
import { debounce } from "./ext";
import { loadImagePromise } from "./io";

export const API_URL = window["runtimeConfig"].REACT_APP_CIE_API_URL_ADDRESS;
export const API_URL_v1 = `${API_URL}/v1`;
export const API_URL_v2 = `${API_URL}/v2`;

const MIN_WINDOW_SIZE_SEC = 5 * 60;

const DEFAULT_ERR_USER =
  "Ha ocurrido un error - Por favor inténtelo nuevamente";

function fetchWithTimeout(url, { timeout, ...options }) {
  cancelPending({ url, options });
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Timeout")), timeout)
    ),
  ]);
}

const ONGOING_CALLS = {};

function cancelPending({ url, options }) {
  const callKey = JSON.stringify({ url, options });
  const ongoing = ONGOING_CALLS[callKey];
  if (ongoing !== undefined) {
    // console.warn("aborting previous call");
    ongoing.abort();
    delete ONGOING_CALLS[callKey];
    // return Promise.reject("New API call replaced old");
  }

  const controller = new AbortController();
  options.signal = controller.signal;
  ONGOING_CALLS[callKey] = controller;
}
export const httpClient = (
  url,
  { cancelPrevious = false, ...options } = {}
) => {
  if (cancelPrevious) {
    cancelPending({ url, options });
  }
  const bearerToken = getBearerToken();
  if (!bearerToken) {
    return Promise.reject();
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set("Authorization", bearerToken);
  return fetchUtils.fetchJson(url, options).catch((error) => {
    console.warn(error, error.body);
    throw error;
  });
};

function filterLogs(events, windowSize) {
  let filteredLogs = [];
  const groups = groupBy(events, (e) => e.event_type);
  for (const [eventType, eventsForEventType] of Object.entries(groups)) {
    const deleteIdxsPerEventType = [];
    if (eventType == "Alarma Activa") {
      filteredLogs.push(...eventsForEventType);
      continue;
    }
    eventsForEventType.sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
    for (let idx = 0; idx < eventsForEventType.length - 1; idx++) {
      const currentEvent = eventsForEventType[idx];
      const nextEvent = eventsForEventType[idx + 1];
      if (
        currentEvent.status !== nextEvent.status &&
        currentEvent.status !== "OK"
      ) {
        const windowSecs =
          (new Date(nextEvent.timestamp) - new Date(currentEvent.timestamp)) /
          1000;
        if (windowSecs < windowSize) {
          // console.warn(currentEvent.timestamp, nextEvent.timestamp, windowSecs)
          deleteIdxsPerEventType.push(idx, idx + 1);
        }
      }
    }
    const keepLogs = eventsForEventType.filter(
      (event, index) => !deleteIdxsPerEventType.includes(index)
    );
    filteredLogs.push(...keepLogs);
  }
  filteredLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  return filteredLogs;
}

function buildLayout(zones) {
  return groupBy(zones, ({ layout_id }) => layout_id);
}

const injectImageData = (verticesPerZone) => {
  const imageUrl = Object.values(verticesPerZone)[0].zoneImageURL;
  return new Promise((resolve) => {
    loadImagePromise(imageUrl).then((img) => {
      resolve({
        imageUrl,
        imageDimensions: {
          height: img.height,
          width: img.width,
        },
        verticesPerZone,
      });
    });
  });
};

/**
 * @typedef {Object} MapData
 * @property {string} zoneImageURL
 * @property {float[]} traces
 * @property {float} start_alpha
 * @property {float} end_alpha
 */

/**
 * @typeof {function} GetMapData
 * @param {number} plantId - path params fot he api
 * @param {Object} query - query params fot he api
 * @returns {Promise<Object<number, MapData>>}
 */

/**
 * @typedef {Object} DataProvider
 * @property {GetMapData} getMapData
 */
export const DataProvider = {
  get_version: () => {
    const url = `${API_URL}/version`;
    return fetchWithTimeout(url, {
      timeout: 500,
      headers: {
        pragma: "no-cache",
        "cache-control": "no-cache",
      },
    }).then((response) => response.text());
  },
  ping: () => {
    return fetchWithTimeout(API_URL, { timeout: 500 })
      .then((response) => {
        return response.text().then((t) => Promise.resolve(t));
      })
      .then((text) => {
        if (text == '"OK"') {
          return Promise.resolve();
        }
        return Promise.reject("API Offline");
      })
      .catch((err) => Promise.reject(err));
    // .catch(
    //   a => {
    //     console.log(a)
    //     debugger
    //     throw a;
    //   }
    // )
  },
  resetPassword: ({ ...params }) => {
    const url = `${API_URL}/auth/reset_password`;
    return fetchUtils
      .fetchJson(url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        // body: JSON.stringify(params)
        body: new URLSearchParams(params),
      })
      .catch((error) => {
        if ([412].includes(error.status)) {
          return Promise.reject(error.body.detail);
        }
        console.warn(error, error.body, error.status);
        return Promise.reject(DEFAULT_ERR_USER);
      });
  },
  checkPassword: ({ ...query }) => {
    let url = `${API_URL}/auth/check_password`;
    if (query) {
      url += `?${stringify(query)}`;
    }
    return fetchUtils
      .fetchJson(url)
      .then(({ json }) => json)
      .catch((error) => {
        if ([410, 418].includes(error.status)) {
          return Promise.reject(error.body.detail);
        }
        console.warn(error, error.body, error.status);
        return Promise.reject(DEFAULT_ERR_USER);
      });
  },
  changePassword: ({ confirmPassword, ...params }) => {
    const url = `${API_URL}/auth/change_password`;
    return fetchUtils
      .fetchJson(url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        // body: JSON.stringify(params)
        body: new URLSearchParams(params),
      })
      .catch((error) => {
        if ([410, 418].includes(error.status)) {
          return Promise.reject(error.body.detail);
        }
        console.warn(error, error.body, error.status);
        return Promise.reject(DEFAULT_ERR_USER);
      });
  },
  getList: (resource, params) => {
    if (resource !== "plants") {
      return Promise.reject();
    }
    const url = `${API_URL_v1}/${resource}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: json.length,
    }));
  },

  getOne: (resource, params) =>
    httpClient(`${API_URL_v1}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${API_URL_v1}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${API_URL_v1}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  getEventsData: (plantId, fromTime, toTime) => {
    const url = `${API_URL_v1}/event/${plantId}`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({
        date_from: fromTime,
        date_to: toTime,
        page_size: 1000,
      }),
    }).then(({ headers, json }) => filterLogs(json, MIN_WINDOW_SIZE_SEC));
  },

  getLayoutData: (plantId, { ...query }) => {
    let url = `${API_URL_v1}/layout/${plantId}`;
    if (query) {
      url += `?${stringify(query)}`;
    }
    return httpClient(url)
      .then(({ json }) => json)
      .then(injectImageData);
  },

  getMapData: (plantId, { ...query }) => {
    let url = `${API_URL_v1}/map/${plantId}`;
    if (query) {
      url += `?${stringify(query)}`;
    }
    return httpClient(url)
      .then(({ json }) => json)
      .then(injectImageData);
  },

  getLatestTraceData: (plantId, { ...query } = {}) => {
    let url = `${API_URL_v1}/trace/${plantId}/latest`;
    if (Object.keys(query).length) {
      url += `?${stringify(query)}`;
    }
    return httpClient(url, { method: "GET" }).then(({ headers, json }) => json);
  },

  getTracesData: (plantId, zoneId, fromTime, toTime, granularity) => {
    var granularityRequest = null;
    switch (granularity) {
      // case 1:
      //   granularityRequest = "1min";
      //   break;
      case 5:
        granularityRequest = "5min";
        break;
      case 30:
        granularityRequest = "30min";
        break;
      case 60:
        granularityRequest = "1h";
        break;
      // case 360:
      //   granularityRequest = "6h";
      //   break;
      // case 1440:
      //   granularityRequest = "24h";
      //   break;
      default:
        granularityRequest = "5min";
    }

    const url = `${API_URL_v1}/trace/${plantId}/${zoneId}`;
    return debounce(httpClient, 300)(url, {
      method: "POST",
      body: JSON.stringify({
        granularity: granularityRequest,
        date_from: fromTime,
        date_to: toTime,
        page_size: 1000,
      }),
    })
      .then(({ headers, json }) => json)
      .catch((err) => console.log(err));
  },

  getZonesData: (plantId, { ...query } = {}) => {
    let url = `${API_URL_v1}/my_zones/${plantId}`;
    if (Object.keys(query).length) {
      url += `?${stringify(query)}`;
    }
    return httpClient(url)
      .then(({ headers, json }) => json)
      .then((json) => {
        return {
          zones: json,
          layouts: buildLayout(json),
        };
      });
  },

  getStatusData: (plantId) => {
    let url = `${API_URL_v1}/summary/`;
    if (plantId !== undefined) {
      url += `${plantId}`;
    }

    return httpClient(url).then(({ json }) => json);
  },

  hideEvent: (id, plantId, user, payload = {}) => {
    if (user !== undefined) {
      payload.user = user;
    }

    const queryParams = stringify(payload);
    const url = `${API_URL_v1}/event/${plantId}/${id}?${queryParams}`;
    return httpClient(url, {
      method: "PATCH",
      body: JSON.stringify({ visible: false }),
    });
    // events = events.filter((e) => e.id != id);
    // return Promise.resolve(events);
  },

  hideEvents: (plantId, fromTime, toTime, payload = {}) => {
    payload = JSON.stringify({
      date_from: fromTime,
      date_to: toTime,
      page_size: 1000,
    });
    const queryParams = stringify(payload);
    const url = `${API_URL_v1}/event/${plantId}?${queryParams}`;
    return httpClient(url, {
      method: "PATCH",
      body: payload,
    });
  },
};

export function getStatusDataHashIgnoringWorkerTimestamps(plants) {
  return JSON.stringify(
    plants.map(({ states, ...plantData }) => ({
      ...plantData,
      states: states.map(
        ({ value: { Errors, LastPoll, ...valueData }, ...stateData }) => ({
          ...stateData,
          value: {
            ...valueData,
            Errors: Errors.map(
              ({ timestamp, id, type, status, ...errorData }) => ({
                type,
                status,
                // FIXME: what about other fields in errodData?
              })
            ),
          },
        })
      ),
    }))
  );
}

export default DataProvider;
