import { groupBy } from "./ext";

/**
 *
 * @typedef {Object} ZoneLocation
 * @property {number} mapping_start_idx - Previous index in the fiber vertices, before
 *  point location.
 * @property {number} mapping_end_idx -  Following index in the fiber vertices, after
 *  point location.
 * @property {number} mapping_alpha - Fraction of the distance between vertices to locate the
 *  point.
 */
/**
 *
 * @typedef {Object} MapVertex
 *  asd fasd fasd fas
 * das dfasd f
 * @property {number} x - x coordinate of the vertex.
 * @property {number} y - y coordinate of the vertex.
 */
/**
 *
 * @typedef {MapVertex} MapVertexReturn
 * @property {number?} anchorIdx - If set, indicates the index of the anchor in the
 *   mapped coordinates.
 */

const DEBUG = [() => null, console.log][0];
const USE_CACHE = false;

function euclideanLength(x, y) {
  return Math.sqrt(x * x + y * y);
}

function euclideanDistance(point1, point2) {
  return euclideanLength(point1.x - point2.x, point1.y - point2.y);
}

function sumPoints(point1, point2) {
  return {
    x: point1.x + point2.x,
    y: point1.y + point2.y,
  };
}

export function interpolatePoint(point1, point2, alpha) {
  return sumPoints(
    point1,
    sumPoints(
      {
        x: point2.x * alpha,
        y: point2.y * alpha,
      },
      {
        x: -alpha * point1.x,
        y: -alpha * point1.y,
      }
    )
  );
}

function computeSegmentLength(vertices, mapPoints, start, nTracePoints) {
  const start_idx = 0;
  const end_idx = vertices.length - 1;
  const offset = euclideanDistance(
    mapPoints[start.mapping_start_idx],
    vertices[0]
  );

  let total = 0;
  const cumSum = [];
  if (offset !== undefined) {
    cumSum.push(offset);
    total += offset;
  }
  for (let idx = start_idx; idx < end_idx; idx++) {
    const startVertex = vertices[idx];
    const endVertex = vertices[idx + 1];
    const distance = euclideanDistance(startVertex, endVertex);
    total += distance;
    cumSum.push(total);
  }

  const nPoints = nTracePoints - 1; // totalPts - 2 (stand, end already included) + 1 (teorema de los postes y las rejas)
  const segmentLength = (total - offset) / nPoints;

  DEBUG("totalLength", total);
  DEBUG("segmentLength", segmentLength);
  return [segmentLength, cumSum];
}

function areSame(value1, value2, eps) {
  return Math.abs(value1 - value2) < (eps || 0.00000001);
}
function areSameVec(value1, value2, eps) {
  return areSame(value1.x, value2.x, eps) && areSame(value1.y, value2.y, eps);
}

function isLowerOrEqualThanWithEpsion(value1, value2, eps) {
  return value1 - value2 <= (eps || 0.00000001);
}

export class NoTracePoints extends Error {
  constructor(message) {
    super(message);
  }
}

export class NoMapPoints extends Error {
  constructor(message) {
    super(message);
  }
}

function validateInputs(mapPoints, nTracePoints, start, end) {
  DEBUG("assign: mapPoints=", mapPoints);
  DEBUG("assign: nTracePoints=", nTracePoints);
  DEBUG("assign: start=", start);
  DEBUG("assign: end=", end);
  if (nTracePoints == 0) {
    throw new NoTracePoints(`nTracePoints=${nTracePoints}`);
  }
  if (!mapPoints.length) {
    throw new NoMapPoints(
      `Empty mapPoints=${JSON.stringify(mapPoints)}, start=${JSON.stringify(
        start
      )}, end=${JSON.stringify(end)}`
    );
  }

  const [firstVertexStart, firstVertexEnd] = [
    mapPoints[start.mapping_start_idx],
    mapPoints[start.mapping_end_idx],
  ];
  const [lastVertexStart, lastVertexEnd] = [
    mapPoints[end.mapping_start_idx],
    mapPoints[end.mapping_end_idx],
  ];

  const vertices = [
    firstVertexStart,
    firstVertexEnd,
    lastVertexStart,
    lastVertexEnd,
  ];
  if (vertices.includes(undefined)) {
    throw new NoMapPoints(
      `Ill-defined zone for mapPoints=${JSON.stringify(
        mapPoints
      )}, start=${JSON.stringify(start)}, end=${JSON.stringify(end)}`
    );
  }

  const firstVertex = interpolatePoint(
    firstVertexStart,
    firstVertexEnd,
    start.mapping_alpha
  );
  const lastVertex = interpolatePoint(
    lastVertexStart,
    lastVertexEnd,
    end.mapping_alpha
  );
  return [firstVertex, lastVertex];
}

function buildChartVertices(mapPoints, firstVertex, lastVertex, start, end) {
  const chartVertices = [
    firstVertex,
    ...mapPoints.slice(start.mapping_end_idx, end.mapping_start_idx + 1),
    lastVertex,
  ];
  if (
    // areSameVec(lastVertex, chartVertices[chartVertices.length-1])
    areSameVec(firstVertex, lastVertex)
  ) {
    chartVertices.pop();
  }
  DEBUG("chartVertices", chartVertices);
  return chartVertices;
}

function validateEffectiveChartVertices(
  mapPoints,
  start,
  end,
  nTracePoints,
  effectiveChartVertices
) {
  const effectiveChartVerticesLength = effectiveChartVertices.length;
  switch (effectiveChartVerticesLength) {
    case 0:
      throw new NoMapPoints(
        `Empty mapPoints=${JSON.stringify(mapPoints)}, start=${JSON.stringify(
          start
        )}, end=${JSON.stringify(end)}`
      );
    case 1:
      switch (nTracePoints) {
        case 1:
          return [{ anchorIdx: 0, ...effectiveChartVertices[0] }];
        default:
          console.warn(
            `Setting all samples to single coordinate.
            Check ill-defined ZONE for [mapPoints, start, end]=\n`,
            mapPoints,
            start,
            end
          );
          return new Array(nTracePoints).fill({
            anchorIdx: 0,
            ...effectiveChartVertices[0],
          });
      }
    case 2:
      switch (nTracePoints) {
        case 1:
          console.warn(
            `Setting single sample to first coordinate.
            Requested nTracePoints=${nTracePoints} for [mapPoints, start, end]=\n`,
            mapPoints,
            start,
            end
          );
          return [{ anchorIdx: 0, ...effectiveChartVertices[0] }];
        // case 2:
        //   break  // this should be handled fine
      }
  }
}

function mapCoordinates(effectiveChartVertices, cumSum, segmentLength) {
  const tempsMappedTo2d = [{ anchorIdx: 0, ...effectiveChartVertices[0] }];
  let nSteps = 0;
  let walked = cumSum[0];
  let finish = cumSum[cumSum.length - 1];
  let lastVertexIdx = 0;
  let attempt = 0;
  // const maxAttempts = effectiveChartVertices.length*(effectiveChartVertices.length-1)/2;
  const maxAttempts = 10000; // fixme compute this!!!
  DEBUG("effectiveChartVertices", effectiveChartVertices);
  DEBUG("maxAttempts", maxAttempts);
  DEBUG("segmentLength", segmentLength);
  DEBUG("finish", finish);
  while (true) {
    attempt++;
    if (attempt > maxAttempts) {
      throw new Error("too many attempts");
    }
    if (areSame(walked, finish)) {
      break;
    } else if (walked > finish) {
      console.error("error during interpolation!");
      break;
    }
    nSteps += 1;
    DEBUG(`step: ${nSteps}`);
    const lastVertex = effectiveChartVertices[lastVertexIdx];
    const previousWalked = walked;
    walked += segmentLength;

    const cumDistanceAtVertices = cumSum.slice(lastVertexIdx, cumSum.length);
    const cumDistanceAtCurrentVertex = cumDistanceAtVertices[0];
    const cumDistanceAtNextVertex = cumDistanceAtVertices[1];
    DEBUG(`  stepping from ${previousWalked} into ${walked} length`);
    DEBUG(`  lastVertex, lastVertexIdx`, lastVertex, lastVertexIdx);

    DEBUG(`  need to check ${cumDistanceAtVertices}`);
    DEBUG(`  cumDistanceAtCurrentVertex: ${cumDistanceAtCurrentVertex}`);
    DEBUG(`  cumDistanceAtNextVertex: ${cumDistanceAtNextVertex}`);

    if (isLowerOrEqualThanWithEpsion(walked, cumDistanceAtNextVertex)) {
      DEBUG(
        `    can walk in same segment because walked  <= cumDistanceAtNextVertex (${walked} <= ${cumDistanceAtNextVertex})!`
      );
      const alpha =
        (walked - cumDistanceAtCurrentVertex) /
        (cumDistanceAtNextVertex - cumDistanceAtCurrentVertex);
      const nextVertex = effectiveChartVertices[lastVertexIdx + 1];
      const incomingTracePoint = interpolatePoint(
        lastVertex,
        nextVertex,
        alpha
      );
      incomingTracePoint.anchorIdx = lastVertexIdx;
      DEBUG(`    alpha: ${alpha}`);
      DEBUG(`    previousVertex: `, lastVertex);
      DEBUG(`    nextVertex: `, nextVertex);
      DEBUG(`    incomingTracePoint: `, incomingTracePoint);
      tempsMappedTo2d.push(incomingTracePoint);
      if (areSame(walked, cumDistanceAtNextVertex)) {
        DEBUG(`    upgrading lastVertexIdx because it hit exactly the vertex!`);
        lastVertexIdx += 1;
      }
      continue;
    }
    DEBUG(
      `    backtracking: can't walk in same segment because walked > cumDistanceAtNextVertex (${walked} > ${cumDistanceAtNextVertex})!`
    );
    walked -= segmentLength;
    lastVertexIdx += 1;
  }
  DEBUG("took", attempt);
  return tempsMappedTo2d;
}

function groupVertices(allVertices) {
  const anchorIdxs = allVertices
    .map((v) => v.isInterpolated)
    .reduceRight(
      (idxs, value, idx) => (value !== undefined ? [idx, ...idxs] : idxs),
      []
    );

  const groups = [];
  for (let idx = 0; idx < anchorIdxs.length - 1; idx++) {
    const from = anchorIdxs[idx];
    const to = anchorIdxs[idx + 1];
    const slice = allVertices.slice(from, to + 1);
    groups.push(slice);
  }
  return groups;
}

// let assign_counter = 0;
/**
 * Assign
 *   asdfasd
 * fasd
 * fasd
 * fa.
 * @example
 * // Interpolate 4 points in the middle of the segments of a unit square.
 * //
 * // Asterists ("*") represent coordinates of the square vertices, and "o"s represent
 * //   the  interpolated positions
 * //
 * //  *---*       *-o-*
 * //  |   |       |   |
 * //  |   |  =>   o   o
 * //  |   |       |   |
 * //  *---*       *-o-*
 *
 * const mapped = assign(
 *   [{x:0,y:0}, {x:1,y:0}, {x:1,y:1}, {x:0,y:1}, {x:0,y:0},], // unit square
 *   4, // number of points to interpolate
 *   {mapping_start_idx:0, mapping_end_idx:1, mapping_alpha: .5}, // middle of top edge
 *   {mapping_start_idx:3, mapping_end_idx:4, mapping_alpha: .5}, //middle of left edge
 * );
 * console.log(mapped);
 * [
 *  {"x":0.5,"y":0,"anchorIdx":0},
 *  {"x":1,"y":0.5,"anchorIdx":1},
 *  {"x":0.5,"y":1,"anchorIdx":2},
 *  {"x":0,"y":0.5,"anchorIdx":3}
 * ]
 *
 *
 * @param {MapVertex[]} mapPoints - 2d consecutive vertices defining a path to
 *  interpolate.
 * @param {number} nTracePoints - Number of points to interpolate.
 * @param {ZoneLocation} start - Location of the first trace point to include
 * @param {ZoneLocation} end - Location of the last trace point to include
 * @returns {MapVertexReturn[]} All map vertices after combining original and interpolated.
 *   Interpolated also contain the anchorIdx field indicating the index of the
 *   tracepoint to be assigned to.
 */
function assign_(mapPoints, nTracePoints, start, end) {
  // console.log("assign call count", ++assign_counter);
  const [firstVertex, lastVertex] = validateInputs(
    mapPoints,
    nTracePoints,
    start,
    end
  );

  const effectiveChartVertices = buildChartVertices(
    mapPoints,
    firstVertex,
    lastVertex,
    start,
    end
  );

  const handled = validateEffectiveChartVertices(
    mapPoints,
    start,
    end,
    nTracePoints,
    effectiveChartVertices
  );
  if (handled) {
    // console.info("Edge case detected, early return triggered");
    return handled;
  }

  const [segmentLength, cumSum] = computeSegmentLength(
    effectiveChartVertices,
    mapPoints,
    start,
    nTracePoints
  );

  const tempsMappedTo2d = mapCoordinates(
    effectiveChartVertices,
    cumSum,
    segmentLength
  );

  DEBUG("tempsMappedTo2d", tempsMappedTo2d);
  return tempsMappedTo2d;
}

// let interpolateAndGroup_counter = 0;
/**
 * Grouped interpolation of 1d temps to 2d traces - each group starting and ending sampled temp.
 *
 *
 * @example
 * //Note both the mapPoints and temps have same starting and ending point
 * temps =  // number of points to interpolate
 * start_alpha = 0;
 * end_alpha = 0;
 * interpolateAndGroup(
 *   [{x:0,y:0}, {x:1,y:0}, {x:1,y:1}, {x:0,y:1}, {x:0,y:0},]; // unit square
 *   [1,2,3,4,5,6,7,8,1],
 *   0,
 *   1,
 * )
 *
 * This should be feasible in same assign function to avoid looping again over vertices?
 */
function interpolateAndGroup_(vertices, temps, start_alpha, end_alpha) {
  // console.log("interpolateAndGroup call count", ++interpolateAndGroup_counter);
  const numVertices = vertices.length;
  const numPointsToInterpolate = temps.length;

  const interpolatedVertices = assign(
    vertices,
    numPointsToInterpolate,
    {
      mapping_start_idx: 0,
      mapping_end_idx: 1,
      mapping_alpha: start_alpha,
    },
    {
      mapping_start_idx: numVertices - 2,
      mapping_end_idx: numVertices - 1,
      mapping_alpha: end_alpha,
    }
  );

  // const rescaledVertices = vertices.map(
  //   v => {
  //     return {
  //       x: v.x * imageDimensions.width,
  //       y: v.y * imageDimensions.height,
  //     }
  //   }
  // );
  // const rescaledInterpolatedVertices = interpolatedVertices.map(
  //   v => {
  //     return {
  //       ...v,
  //       x: v.x * imageDimensions.width,
  //       y: v.y * imageDimensions.height,
  //     }
  //   }
  // );

  const allVertices = [
    ...vertices.map((v, i) => {
      return { ...v, anchorIdx: i };
    }),
    ...interpolatedVertices.map((v, i) => {
      return {
        ...v,
        isInterpolated: true,
        temp: temps[i],
        tempIndex: i,
      };
    }),
  ];

  const sortedVertices = Object.values(
    groupBy(allVertices, (v) => v.anchorIdx)
  ).flat();
  const grouped = groupVertices(sortedVertices);
  return grouped;
}

// let getMinMax_counter = 0;
/**
 *
 * @param {*} arr
 * @returns {[number, number]} Minimum and Maximum
 */
const getMinMax_ = (arr) => {
  // console.log("getMinMax call count", ++getMinMax_counter);
  return arr.reduce(
    ([min, max], v) => [Math.min(min, v), Math.max(max, v)],
    [Infinity, -Infinity]
  );
};

class Cache {
  serialize = (raw_key) => {
    const start = Date.now();
    const key = JSON.stringify(raw_key);
    const end = Date.now();
    DEBUG(`seralization time : ${end - start} ms`);
    return key;
  };

  get = (key) => {
    return this[key];
  };

  set = (key, value) => {
    this[key] = value;
  };

  compute = (func) => {
    const that = this;
    function wrapped(...all_args) {
      const start = Date.now();
      const key = that.serialize(...arguments);
      let value = that.get(key);
      if (value == undefined) {
        DEBUG("cache miss!");
        value = func(...arguments);
        that.set(key, value);
        DEBUG("cache stored!");
      } else {
        DEBUG("cache hit!");
      }
      const end = Date.now();
      DEBUG(`compute time: ${end - start} ms`);
      return value;
    }
    return wrapped;
  };
}

const CACHE = new Cache();
export let assign;
export let interpolateAndGroup;
export let getMinMax;
if (USE_CACHE) {
  assign = CACHE.compute(assign_);
  interpolateAndGroup = CACHE.compute(interpolateAndGroup_);
  getMinMax = CACHE.compute(getMinMax_);
} else {
  assign = assign_;
  interpolateAndGroup = interpolateAndGroup_;
  getMinMax = getMinMax_;
}
