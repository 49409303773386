import { orange } from "@mui/material/colors";
import { deepOrange } from "@mui/material/colors";
import { blue } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { red } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import { indigo } from "@mui/material/colors";
import { defaultTheme } from "react-admin";

export const CustomTheme = {
  ...defaultTheme,
  sidebar: {
    width: 65,
    closedWidth: 65,
  },
  palette: {
    mode: "light",
    secondary: {
      main: indigo[800],
      contrastText: grey[100],
    },
    primary: {
      main: deepOrange[700],
      contrastText: grey[100],
    },
    background: {
      default: grey[400],
      dark: grey[500],
      light: grey[200],
      contrastText: grey[700],
    },
    text: {
      main: grey[100],
      dark: grey[800],
    },
    error: {
      main: red[900],
    },
    warning: {
      main: orange[900],
    },
    success: {
      main: green[900],
    },
    info: {
      main: blue[900],
    },
  },
  components: {
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          "& .RaMenuItemLink-icon": {
            color: grey[800],
          },
          "&:hover .RaMenuItemLink-icon": {
            color: grey[100],
          },
          "&:focus .RaMenuItemLink-icon": {
            color: grey[100],
          },
        },
      },
    },
  },
};
