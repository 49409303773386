import { useEffect } from "react";
import { useGetIdentity } from "react-admin";
import { useRedirect } from "react-admin";

import Error from "../components/Error";
import Loading from "../components/Loading";

const AdminDashboard = ({}) => {
  const { data, isLoading, error } = useGetIdentity();
  const redirect = useRedirect();

  useEffect(() => {
    if (isLoading | error) {
      return;
    }
    console.log("data.plant_id", data.plant_id, data.plant_id !== null);
    if (data.plant_id !== null) {
      redirect(`realtime/${data.plant_id}`);
      return;
    }
    redirect(`home`);
  });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
};

export default AdminDashboard;
