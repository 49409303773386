import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Plot from "react-plotly.js";

import useData from "../../hooks/useData";
import { ZonesContext } from "../../utils/Ctx";
import { COLORRANGE } from "../../utils/consts";
import Error from "../Error";
import Loading from "../Loading";
import DownloadExcelReport from "./ExportExcel";
import { ColorScaleHeader } from "./StatsHeader";

import moment from 'moment-timezone';



const CONTRAST_COLOR = "black";

const LimitedQuerySnackbar = ({ info }) => {
  const [open, setOpen] = useState(info.too_much_data);
  if (!info.too_much_data) {
    return <></>;
  }
  if (info.too_much_data) {
    if (open !== info.too_much_data) {
      setOpen(true);
      return <></>;
    }
  }
  const msg =
    (info.msg || "") +
    (info.new_start_dt ? new Date(info.new_start_dt).toLocaleString() : "");

  const handleClose = (event, reason) => {
    if (reason == "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      message={msg}
      anchorOrigin={{ vertical: "bottom", horizontal: "middle" }}
    />
  );
};

const HeatMapTitle = ({
  colorScaleValues,
  setColorScaleValues,
  yaxis,
  zaxis,
}) => {
  const nValues = yaxis.length;
  const minTemps = zaxis.map((values) => Math.min(...values));
  const maxTemps = zaxis.map((values) => Math.max(...values));
  const fiberSize = zaxis[0] ? zaxis[0].length : 0;

  const avgTempValue =
    (1 / (nValues * fiberSize)) *
    zaxis
      .map((values) => values.reduce((x, y) => x + y, 0))
      .reduce((x, y) => x + y, 0);

  const minTempValue = Math.min(...minTemps);
  const maxTempValue = Math.max(...maxTemps);

  return (
    <>
      <Grid
        container
        direction={"row"}
        spacing={2}
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={10}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ColorScaleHeader
            colorScaleValues={colorScaleValues}
            setColorScaleValues={setColorScaleValues}
            colorRange={COLORRANGE}
          />
        </Grid>

        <Grid
          container
          item
          direction={"row"}
          xs={10}
          spacing={5}
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            item
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item>
              <Typography variant="h6" align="center">
                Mínima:
              </Typography>
            </Grid>
            <Grid item>
              <Typography color={COLORRANGE[1]} variant="h5" align="center">
                {minTempValue.toFixed(1)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                Promedio:
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color={COLORRANGE[parseInt(COLORRANGE.length / 2 - 3)]}
                variant="h5"
                align="center"
              >
                {avgTempValue.toFixed(1)}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h6" align="center">
                Máxima:
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color={COLORRANGE[COLORRANGE.length - 1]}
                variant="h5"
                align="center"
              >
                {maxTempValue.toFixed(1)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const HeatmapPlot = ({
  values,
  info,
  dateSelectorBlinks,
  setDateSelectorBlinks,
  minTempValue,
  maxTempValue,
  alarmTemp,
  zoneId,
  granularity,
}) => {
  const zones = useContext(ZonesContext);
  const zone = zones.filter((z) => z.id == zoneId)[0];
  const [colorScaleValues, setColorScaleValues] = useState([
    parseInt(minTempValue - 1),
    parseInt(maxTempValue + 1),
  ]);

  useEffect(() => {
    if (dateSelectorBlinks !== info.too_much_data) {
      setDateSelectorBlinks(info.too_much_data);
    }
  });

  const xaxis = values.map((trace, index) => index * trace.sampling_interval);
  const yaxis = values.map((trace) => moment(trace.TIME_STAMP).tz('America/Santiago').format());
  const zaxis = values.map((trace) => trace.TEMP_DATA);

  const colorScale = COLORRANGE.map((color, index) => [
    `${parseFloat(index / (COLORRANGE.length - 1)).toFixed(1)}`,
    color,
  ]);

  const data = [
    {
      x: xaxis,
      y: yaxis,
      z: zaxis,
      type: "heatmap",
      hovertemplate: "[%{y}]@%{x}m: %{z:.2f}°C ",
      name: "Temperatura",
      visible: true,
      showlegend: false,
      showscale: false,
      zmin: parseInt(Math.max(colorScaleValues[0], -1)),
      zmax: parseInt(colorScaleValues[1]),
      colorscale: colorScale,
      colorbar: {
        outlinewidth: 0,
        tickfont: {
          color: CONTRAST_COLOR,
        },
      },
      hoverlabel: {
        namelength: -1,
        bgcolor: "black",
        font: {
          size: 24,
        },
      },
    },
  ];

  const layout = {
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    autosize: true,
    margin: {
      l: 95,
      t: 10,
      b: 25,
      r: 10,
    },
    yaxis: {
      color: CONTRAST_COLOR,
      gridcolor: CONTRAST_COLOR,
      showgrid: false,
      showline: false,
    },
    xaxis: {
      color: CONTRAST_COLOR,
      gridcolor: CONTRAST_COLOR,
      showgrid: false,
      showline: false,
    },
  };
  return (
    <>
      <LimitedQuerySnackbar info={info} />
      <HeatMapTitle
        colorScaleValues={colorScaleValues}
        setColorScaleValues={setColorScaleValues}
        yaxis={yaxis}
        zaxis={zaxis}
      />
      <Plot
        data={data}
        layout={layout}
        config={{ displayModeBar: false }}
        useResizeHandler={true}
        style={{ width: "100%", height: "80%" }}
      />
      <DownloadExcelReport
        values={values}
        fileName={`Temperaturas ${zone.name}`}
        zone={zone}
        granularity={granularity}
        samplingInterval={info.sampling_interval}
      />
    </>
  );
};

const Heatmap = ({
  plantId,
  zoneId,
  granularity,
  fromTime,
  setFromTime,
  toTime,
  dateSelectorBlinks,
  setDateSelectorBlinks,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  let tracesResponse;
  tracesResponse = useData(
    (provider) => {
      setIsLoading(true);
      return provider
        .getTracesData(plantId, zoneId, fromTime, toTime, granularity)
        .then((r) => {
          setIsLoading(false);
          return r;
        });
    },
    { deps: [plantId, zoneId, fromTime, toTime, granularity] }
  );

  if (isLoading || tracesResponse.loading) {
    return <Loading />;
  }
  if (tracesResponse.error) {
    return <Error />;
  }
  const { traces_data, info } = tracesResponse.data;

  if (info == "NO DATA") {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ErrorOutlineIcon fontSize="large" color="warning" sx={{ mb: 1 }} />
        <Typography color="warning" variant="h5">
          {"No se han encontrado datos."}
        </Typography>
        <Typography color="warning" variant="h5">
          {"Modifique las fechas de búsqueda."}
        </Typography>
      </Box>
    );
  } else if (info.too_much_data && info.new_start_dt !== fromTime) {
    // console.warn(info.new_start_dt, fromTime, info.new_start_dt!==fromTime) //FIXME accurate comparison between frontend and backend's date_from
    // setFromTime(info.new_start_dt)
  }
  const minTempValue = Math.min(...traces_data.map((value) => value.MIN_TEMP));
  const maxTempValue = Math.max(...traces_data.map((value) => value.MAX_TEMP));
  return (
    <HeatmapPlot
      values={traces_data}
      info={info}
      dateSelectorBlinks={dateSelectorBlinks}
      setDateSelectorBlinks={setDateSelectorBlinks}
      minTempValue={minTempValue}
      maxTempValue={maxTempValue}
      alarmTemp={60}
      zoneId={zoneId}
      granularity={granularity}
    />
  );
};

export default Heatmap;
