import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import { LoginBase } from "./Login/Login";

export const Deploying = ({ text, ...props }) => {
  const avatar = (
    <Stack spacing={2} alignItems="center">
      <Typography>{text === undefined ? "Cargando..." : text}</Typography>
      <CircularProgress />
    </Stack>
  );

  return (
    <LoginBase
      backgroundImage="img/LoginBackground.png"
      logoImage="img/LogoWhite.png"
      avatar={avatar}
    />
  );
};

export default Deploying;
