import LockIcon from "@mui/icons-material/Lock";
import { Avatar } from "@mui/material";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { CardContent } from "@mui/material";
import { CircularProgress } from "@mui/material";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { useCheckAuth } from "ra-core";
import { Form } from "ra-core";
import { required } from "ra-core";
import { useTranslate } from "ra-core";
import { useSafeSetState } from "ra-core";
import { useLogin } from "ra-core";
import { useNotify } from "ra-core";
import * as React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { TextInput } from "react-admin";
import { useNavigate } from "react-router-dom";

import useData from "../../hooks/useData";
import Deploying from "../Deploying";

const PREFIX = "RaLoginForm";

const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 400,
    color: "",
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

const CustomLoginForm = (props) => {
  const { onSubmit, className, title, redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();

  const login = useLogin();
  const notify = useNotify();

  const submit = (values) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          {
            type: "error",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          }
        );
      });
  };

  return (
    <StyledForm
      onSubmit={submit}
      mode="onChange"
      noValidate
      className={className}
    >
      <CardContent className={LoginFormClasses.content}>
        <Typography
          variant="h4"
          align="center"
          color={"background.light"}
          sx={{ mb: 9 }}
        >
          Iniciar sesión
        </Typography>
        <TextInput
          autoFocus
          source="username"
          color="primary"
          label={translate("ra.auth.username")}
          type="email"
          autoComplete="username"
          validate={required()}
          fullWidth
          sx={{
            input: {
              color: "primary.light",
            },
            "& .MuiTextField-root": {
              border: "1px solid",
              borderColor: "background.light",
            },
            "& .MuiInputLabel-root": {
              fontSize: 20,
              color: "background.light",
            },
            "&. label": {
              "&.Mui-focused": {
                color: "error",
              },
            },
          }}
        />

        <TextInput
          autoFocus
          source="password"
          label={translate("ra.auth.password")}
          type="password"
          autoComplete="current-password"
          validate={required()}
          fullWidth
          color="primary"
          sx={{
            input: {
              color: "primary.light",
            },
            "& .MuiTextField-root": {
              border: "1px solid",
              borderColor: "primary.light",
            },
            "& .MuiInputLabel-root": {
              fontSize: 20,
              color: "background.light",
            },
          }}
        />

        <Button
          variant="contained"
          type="submit"
          justifyContent="center"
          color="primary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
          sx={{
            backgroundColor: "primary.main",
          }}
        >
          {loading ? (
            <CircularProgress
              className={LoginFormClasses.icon}
              size={19}
              thickness={3}
            />
          ) : (
            translate("ra.auth.sign_in")
          )}
        </Button>
      </CardContent>
    </StyledForm>
  );
};

export const LoginBase = (props) => {
  const {
    children,
    backgroundImage,
    avatar,
    logoImage,
    checkAuth,
    onAuth,
    onAuthFailed,
    loginForm,
    ...rest
  } = props;
  const containerRef = useRef();
  let backgroundImageLoaded = false;
  useEffect(() => {
    (checkAuth || ((obj, bool) => Promise.resolve()))({}, false)
      .then(() => {
        // already authenticated
        (onAuth || (() => null))();
      })
      .catch(() => {
        // not authenticated, stay on the login page
        (onAuthFailed || (() => null))();
      });
  }, [checkAuth, onAuth, onAuthFailed]);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    <Grid
      container
      ref={containerRef}
      sx={{
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        alignItems: "center",
        justifyContent: "space-between",
        "& .RaLogin-avatar": {
          margin: "1em",
          display: "flex",
          justifyContent: "center",
        },
        "& .RaLogin-icon": {},
      }}
    >
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          backgroundColor: "rgba(26,35,126,0.85)",
        }}
      >
        <Typography
          variant="h2"
          align="center"
          sx={{
            mt: 18,
            color: "background.light",
          }}
        >
          CENTRO INTEGRADO EMERGENCIAS
        </Typography>
        <Box
          sx={{
            backgroundColor: "secondary.dark",
            borderRadius: 3,
            px: 4,
            py: 6,
          }}
        >
          {loginForm}
          {props.children}
        </Box>
        <Box
          sx={{
            backgroundColor: "secondary.dark",
            display: "flex",
            flexDirection: "column",
            height: "50px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={logoImage} height={"30px"} />
        </Box>
        {/* </Box> */}
      </Grid>
    </Grid>
  );
};

export const LoginPage = () => {
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();

  const apiAvailable = useData((provider) => provider.ping(), {
    pollMs: 45000,
  });

  if (apiAvailable.loading) {
    console.log("api available: loading");
    return <Deploying />;
  }
  if (apiAvailable.error) {
    console.log("api available: error");
    return <Deploying />;
  }

  return (
    <LoginBase
      backgroundImage="img/LoginBackground.png"
      logoImage="img/LogoWhite.png"
      checkAuth={checkAuth}
      loginForm={<CustomLoginForm />}
      avatar={
        <Avatar sx={{ backgroundColor: "primary.light" }}>
          <LockIcon sx={{ backgroundColor: "primary.light" }} />
        </Avatar>
      }
      onAuth={() => navigate("/")} // redirect to the home page
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          href="/#/resetPassword"
          align="center"
          sx={{
            textAlign: "center",
            m: 1,
            color: "primary",
          }}
        >
          Cambiar contraseña
        </Link>
      </Box>
    </LoginBase>
  );
};
export default LoginPage;
