/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
export function groupBy(list, keyGetter) {
  const map = {};
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map[key];
    if (collection == undefined) {
      map[key] = [item];
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function isInside(value, range) {
  return value >= range[0] && value <= range[1];
}
export function isInsideN(vertex, ranges) {
  return Object.entries(ranges)
    .map((coordinateName, coordinateRange) =>
      isInside(vertex[coordinateName], coordinateRange)
    )
    .reduce((a, b) => a && b);
}

export function toLocalTime(datetime) {
  const date = new Date(datetime);
  return `${date.toLocaleDateString("es-cl")} - ${date.toLocaleTimeString(
    "es-cl"
  )}`;
}

export function debounce(func, waitFor) {
  let timeout;
  return (...args) =>
    new Promise((resolve) => {
      console.log("DEBOUNCE: def func=", func);
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        console.log("DEBOUNCE: calling func=", func);
        return resolve(func(...args));
      }, waitFor);
    });
}

export function initializeSelection(zId, zoneLayouts, zones) {
  let blueprintId;
  let zoneId;
  if (zId == undefined) {
    blueprintId = Object.keys(zoneLayouts)[0];
    zoneId = zoneLayouts[blueprintId][0].id;
  } else {
    zoneId = zId;
    blueprintId = zones.filter(z=>z.id==zId)[0].layout_id;
  }

  const blueprintIdInt = parseInt(blueprintId);
  const zoneIdInt = parseInt(zoneId);
  if (isNaN(blueprintIdInt)) {
    console.error("Invalid blueprint identifier (int):", blueprintIdInt);
  }
  if (isNaN(zoneIdInt)) {
    console.error("Invalid zone identifier (int):", zoneIdInt);
  }
  return { blueprintId: blueprintIdInt, zoneId: zoneIdInt };
}
