import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import chroma from "chroma-js";
import { useState, useEffect, memo, useMemo } from "react";
import { Title } from "react-admin";
import { useParams } from "react-router-dom";

import Error from "../../components/Error";
import Loading from "../../components/Loading";
import { RealTimePlantLayout } from "../../components/RealTime/Display/RealTimePlantLayout";
import RealTimeTracesDisplay from "../../components/RealTime/Display/RealTimeTracesDisplay";
import { RealTimeHeader } from "../../components/RealTime/Header";
import useCieApi from "../../hooks/useCieApi";
import useCieWebSocket from "../../hooks/useCieWebSocket";
import { PlantContext } from "../../utils/Ctx";
import { REFRESH_MS, COLORRANGE } from "../../utils/consts";

function extractPlantIds(plants) {
  return JSON.stringify(plants.map(({ id }) => id));
}

function findBPIdByZId(layouts, zId) {
  for (const [key, array] of Object.entries(layouts)) {
    const found = array.some((element) => element.id === zId);
    if (found) {
      return parseInt(key);
    }
  }
  return parseInt(Object.keys(layouts)[0]);
}

export function buildColorScale(colorScale, colorRange) {
  console.debug("[buildColorScale] colorScale: ", colorScale);
  if (colorScale === null) {
    return null;
  }
  const [min, max] = colorScale;
  const points = [...Array(max - min).keys()].map((point) => point + min);
  const colorscale = chroma.scale(colorRange).domain(points); //Get n colors programatically?
  return colorscale;
}

const RealTimeForSelection = ({
  traces,
  plantId,
  blueprintId,
  zoneIds,
  setZoneIds,
  layoutData,
  zones,
  zoneLayouts,
  colorscale,
}) => {
  console.debug("[RealTimeForSelection] layoutData", layoutData, zoneIds);
  if (zoneIds == null) {
    return <Loading height={"calc(100vh - 52px)"} />;
  }
  return (
    <Grid
      container
      spacing={1}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item xs={12} lg={8}>
        <RealTimePlantLayout
          plantId={plantId}
          blueprintId={blueprintId}
          tempsPerZone={traces}
          zoneIds={zoneIds}
          setZoneIds={setZoneIds}
          colorscale={colorscale}
          zones={zones}
          zoneLayouts={zoneLayouts}
          layoutData={layoutData}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <RealTimeTracesDisplay
          zoneIds={zoneIds}
          zones={zones}
          traces={traces}
        />
      </Grid>
    </Grid>
  );
};

const RealTimeForPlant = ({ plantId }) => {
  const [plantIdContext, setPlantIdContext] = useState(plantId);
  const zonesResponse = useCieApi(
    (provider) => provider.getZonesData(plantId),
    [plantId]
  );

  if (!zonesResponse.initialized) {
    return <Loading height={"calc(100vh - 52px)"} />;
  }
  if (zonesResponse.error) {
    return <Error height={"calc(100vh - 52px)"} />;
  }

  const zoneData = zonesResponse.data;

  const zones = zoneData.zones;
  const zoneLayouts = zoneData.layouts;

  return (
    <PlantContext.Provider value={[plantIdContext, setPlantIdContext]}>
      <RealTimeForLayouts
        plantId={plantId}
        zones={zones}
        zoneLayouts={zoneLayouts}
      />
    </PlantContext.Provider>
  );
};

const RealTimeForTraces = ({
  plantId,
  blueprintId,
  setBlueprintId,
  zones,
  zoneLayouts,
  layoutData,
  traceRevision,
}) => {
  let { zoneId } = useParams();
  const [colorScaleValues, setColorScaleValues] = useState([0, 50]);
  const [zoneIds, setZoneIds] = useState(null);

  useEffect(() => {
    if (zoneId !== undefined && zoneIds === null) {
      setZoneIds([parseInt(zoneId)]);
    }
  }, [zoneIds]);
  const colorscale = useMemo(
    () => buildColorScale(colorScaleValues, COLORRANGE),
    [colorScaleValues]
  );

  console.debug("[RealTimeForTraces] blueprintId", blueprintId);
  console.debug("[RealTimeForTraces] zoneLayouts", zoneLayouts);
  console.debug("[RealTimeForTraces] layoutData", layoutData);
  console.debug("[RealTimeForTraces] traceRevision", traceRevision);

  const tracesResponse = useCieApi(
    (dataProvider) =>
      dataProvider.getLatestTraceData(plantId, { blueprint_id: blueprintId }),
    [traceRevision, plantId, blueprintId],
    {
      hashFunction: JSON.stringify,
      throttleMs: 5000,
    }
  );

  if (!tracesResponse.initialized) {
    return <Loading height={"calc(100vh - 52px)"} />;
  }

  if (tracesResponse.error) {
    return <Error height={"calc(100vh - 52px)"} />;
  }
  const traces = tracesResponse.data;
  return (
    <Box>
      {/* <LinearProgress variant="determinate" color="success" value={progress} /> */}
      <RealTimeHeader
        plantId={plantId}
        blueprintId={blueprintId}
        colorScaleValues={colorScaleValues}
        zoneIds={zoneIds}
        setBlueprintId={setBlueprintId}
        setColorScaleValues={setColorScaleValues}
        setZoneIds={setZoneIds}
        traces={traces}
        zoneLayouts={zoneLayouts}
        statusDataRevision={0}
      />
      <RealTimeForSelection
        traces={traces}
        plantId={plantId}
        blueprintId={blueprintId}
        zones={zones}
        zoneIds={zoneIds}
        setZoneIds={setZoneIds}
        layoutData={layoutData}
        zoneLayouts={zoneLayouts}
        colorscale={colorscale}
      />
    </Box>
  );
};

const Timer = memo(({ setTraceRevision }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      console.warn("Bump revision", new Date());
      setTraceRevision((prev) => prev + 1);
    }, REFRESH_MS);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);
});

const RealTimeForLayouts = ({ plantId, zones, zoneLayouts }) => {
  let { zoneId } = useParams();

  const [blueprintId, setBlueprintId] = useState(null);
  const [layoutData, setLayoutData] = useState(null);

  const [traceRevision, setTraceRevision] = useState(0);

  const plantBlueprintIds = useMemo(() => {
    if (zoneLayouts == null) {
      return [];
    } else {
      return Object.keys(zoneLayouts).map((key) => parseInt(key));
    }
  }, [zoneLayouts]);

  useEffect(() => {
    if ((blueprintId === null) & (plantBlueprintIds.length > 0)) {
      if (zoneId !== undefined) {
        const blueprintId = findBPIdByZId(zoneLayouts, parseInt(zoneId));
        setBlueprintId(blueprintId);
        return;
      }
      setBlueprintId(plantBlueprintIds[0]);
    }
  }, [plantBlueprintIds]);

  console.warn("[RealTimeForLayouts] blueprintId", blueprintId);
  const layoutResponse = useCieApi(
    (provider) =>
      provider.getLayoutData(plantId, {
        blueprint_id:
          blueprintId === null
            ? zoneId !== undefined
              ? findBPIdByZId(zoneLayouts, parseInt(zoneId))
              : plantBlueprintIds[0]
            : blueprintId,
      }),
    [plantId, blueprintId]
  );

  useEffect(() => {
    if (layoutResponse.initialized & !layoutResponse.error) {
      setLayoutData(layoutResponse.data);
    }
  }, [layoutResponse]);

  if (!layoutResponse.initialized) {
    return <Loading height={"calc(100vh - 52px)"} />;
  }
  if (layoutResponse.error) {
    return <Error height={"calc(100vh - 52px)"} />;
  }

  return (
    <>
      <Timer setTraceRevision={setTraceRevision} />
      <RealTimeForTraces
        plantId={plantId}
        zones={zones}
        zoneLayouts={zoneLayouts}
        layoutData={layoutData}
        blueprintId={blueprintId}
        setBlueprintId={setBlueprintId}
        traceRevision={traceRevision}
      />
    </>
  );
};

const RealTimePlant = () => {
  let { plantId } = useParams();
  const [statusDataRevision, setStatusDataRevision] = useState(0);

  useCieWebSocket(
    (incoming) => {
      console.warn(
        "[RealTimePlant] got new status info, upgrading statusDataRevision. incoming= ",
        incoming
      );
      console.warn(
        "[RealTimePlant], upgrading statusDataRevision from ",
        statusDataRevision
      );
      setStatusDataRevision(statusDataRevision + 1);
    },
    {
      queueSize: 30,
      sendNotifications: true,
    }
  );

  const plantsResponse = useCieApi(
    (dataProvider) => dataProvider.getStatusData(),
    [],
    {
      hashFunction: extractPlantIds,
      throttleMs: 5000,
    }
  );
  if (!plantsResponse.initialized) {
    return <Loading />;
  }

  if (plantsResponse.error) {
    return <Error />;
  }

  const plant = plantsResponse.data[0];

  if (plantId == undefined) {
    plantId = plant.id;
  }

  const img = plant.logo ? (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid
        item
        justifyContent="center"
        alignItems="center"
        sx={{
          mt: "10px",
        }}
      >
        <Box
          component="img"
          sx={{
            height: 32,
            px: "16px",
          }}
          alt={plant.name}
          src={plant.logo}
        />
      </Grid>
      <span>{plant.name}</span>
    </Grid>
  ) : (
    <span>{plant.name}</span>
  );

  return (
    <>
      <Title title={img} />
      <RealTimeForPlant plantId={plantId} />
    </>
  );
};

export default RealTimePlant;
