import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { green, red, amber } from "@mui/material/colors";
import { useState, useRef, useLayoutEffect, useEffect } from "react";

import SinglePlantOverview from "../../components/Overview/SinglePlantOverview";

const borderColors = {
  OK: green,
  Warning: amber,
  Error: red,
};

const StatusIcon = ({ status, iconSize }) => {
  switch (status) {
    case "OK":
      return (
        <Avatar
          sx={{
            backgroundColor: green[200],
            width: iconSize,
            height: iconSize,
          }}
        >
          <CheckCircleOutlineIcon
            sx={{ color: green[800], fontSize: iconSize }}
          />
        </Avatar>
      );
    case "Warning":
      return (
        <Avatar
          sx={{
            backgroundColor: amber[200],
            width: iconSize,
            height: iconSize,
          }}
        >
          <ErrorOutlineIcon sx={{ color: amber[800], fontSize: iconSize }} />
        </Avatar>
      );
    case "Error":
      return (
        <Avatar
          sx={{ backgroundColor: red[200], width: iconSize, height: iconSize }}
        >
          <ErrorOutlineIcon sx={{ color: red[800], fontSize: iconSize }} />
        </Avatar>
      );
    default:
      break;
  }
};

const PlantsOverviewHeader = ({
  plants,
  containerHeight,
  activeStep,
  setActiveStep,
}) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(containerHeight);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWidth(ref.current.clientWidth);
      setHeight(ref.current.clientHeight);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const cardHeight = parseInt(0.8 * height);
  const fontSize = parseInt(0.5 * cardHeight);
  const iconSize = parseInt(0.15 * cardHeight);

  const activePlants = plants.filter((plant) => plant.active);
  const allStatus = {
    OK: "Ok",
    Warning: "Revisar",
    Error: "Error",
  };

  const SingleCard = ({ cards, activeStep, setActiveStep }) => {
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const NextButton = () => (
      <Button
        onClick={handleNext}
        disabled={activeStep == Object.keys(allStatus).length - 1}
        sx={{
          color: "white",
          maxWidth: "15px",
          maxHeight: "15px",
          minWidth: "15px",
          minHeight: "15px",
          mr: 0.5,
        }}
      >
        <KeyboardArrowRight />
      </Button>
    );

    const BackButton = () => (
      <Button
        onClick={handleBack}
        disabled={activeStep == 0}
        sx={{
          color: "white",
          maxWidth: "15px",
          maxHeight: "15px",
          minWidth: "15px",
          minHeight: "15px",
          ml: 0.5,
        }}
      >
        <KeyboardArrowLeft />
      </Button>
    );
    return (
      <Grid
        container
        direction="row"
        sx={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.light",
          borderRadius: 3,
          height: containerHeight,
        }}
      >
        <BackButton />
        {cards[activeStep]}
        <NextButton />
      </Grid>
    );
  };

  const AllCards = Object.keys(allStatus).map((status, idx) => {
    const statusCount = activePlants
      .filter((plant) => plant.overall_status == status)
      .length.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const backgroundColor =
      statusCount > 0 ? borderColors[status][800] : borderColors[status][300];

    return (
      <Grid
        key={idx}
        container
        direction="row"
        display="flex"
        justify="center"
        sx={{
          alignItems: "center",
          alignContent: "center",
          backgroundColor: "white",
          borderRadius: 3,
          minWidth: cardHeight,
          width: cardHeight,
          height: cardHeight,
          mx: 1,
          p: 1,
        }}
      >
        <Grid
          container
          item
          sx={{
            width: "20%",
            height: "100%",
            direction: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 3,
            backgroundColor: { backgroundColor },
            mr: 0.5,
          }}
        >
          <StatusIcon status={status} iconSize={iconSize} />
        </Grid>
        <Grid
          container
          item
          direction="column"
          sx={{
            width: "75%",
          }}
        >
          <Grid item>
            <Typography
              align={"center"}
              variant={"h3"}
              sx={{
                color: backgroundColor,
                fontSize: fontSize,
                lineHeight: 1,
              }}
            >
              {statusCount}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              align={"center"}
              variant={"body1"}
              noWrap
              sx={{
                color: backgroundColor,
                fontSize: parseInt(0.3 * fontSize),
              }}
            >
              {allStatus[status]}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid
      ref={ref}
      container
      direction="row"
      sx={{
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-evenly",
        borderRadius: 3,
        height: containerHeight,
      }}
    >
      {width / cardHeight > 3.32 ? ( //FIXME: Proper responsiveness
        AllCards
      ) : (
        <SingleCard
          cards={AllCards}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      )}
    </Grid>
  );
};

const PlantsOverviewBody = ({
  plants,
  expanded,
  handleChange,
  containerHeight,
}) => {
  return (
    <Box
      sx={{
        height: containerHeight,
      }}
    >
      <Box
        sx={{
          height: "100%",
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        {plants.map((plant) => {
          return (
            <SinglePlantOverview
              key={plant.id}
              onChange={handleChange(plant.id)}
              expanded={expanded == plant.id}
              plant={plant}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const PlantsOverview = ({
  plants,
  expanded,
  setExpanded,
  setSelectedPlantId,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : 0);
    if (!isExpanded) {
      setSelectedPlantId(id);
    }
  };

  const headerProportion = 0.2;
  const headerHeight = parseInt(headerProportion * window.innerHeight);
  const bodyHeight = parseInt((0.9 - headerProportion) * window.innerHeight);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "background.default",
      }}
    >
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{
          my: 1,
          mx: 2,
          px: 2,
        }}
      >
        <Grid item>
          <PlantsOverviewHeader
            plants={plants}
            containerHeight={headerHeight}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Grid>
        <Grid item>
          <PlantsOverviewBody
            plants={plants}
            expanded={expanded}
            handleChange={handleChange}
            containerHeight={bodyHeight}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlantsOverview;
