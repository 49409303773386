import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { backgroundColors } from "../../utils/consts";
import { borderColors } from "../../utils/consts";
import { stateIcons } from "../../utils/consts";
import { toLocalTime } from "../../utils/ext";

const PlantStates = ({ state }) => {
  const name = state.name;
  const status = state.value.STATUS;
  const details = state.value.Errors;

  return (
    <Accordion
      disabled={status == "OK"}
      sx={{
        mb: 0.5,
        borderRadius: 1,
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton
            sx={{
              ":hover": {
                backgroundColor:
                  backgroundColors[status] ?? backgroundColors["Default"],
                color: "text.dark",
              },
              border: 2,
              borderRadius: 2,
              color: "text.dark",
              backgroundColor:
                backgroundColors[status] ?? backgroundColors["Default"],
            }}
          >
            {stateIcons[name][status]}
          </IconButton>
        }
        sx={{
          "& .MuiAccordionSummary-expandIconWrapper": {
            transition: "none",
            "&.Mui-expanded": {
              transform: "none",
            },
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "text.dark",
          }}
        >
          {name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {details.map((error) => {
          return (
            <Box
              index={error.id}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                border: 2,
                borderRadius: 2,
                borderColor: borderColors[status] ?? borderColors["Default"],
                backgroundColor:
                  backgroundColors[status] ?? backgroundColors["Default"],
                mb: 0.5,
                px: 2,
                py: 0.25,
              }}
              key={error.type}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {stateIcons[name][status]}
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mx: 1,
                }}
              >
                <Typography
                  variant="h6"
                  align="left"
                  color={borderColors[status] ?? "text.dark"}
                >
                  {error.type}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: 1.5,
                }}
              ></Box>
              <Typography
                variant="body2"
                align="left"
                color={borderColors[status] ?? "text.dark"}
              >
                {error.timestamp
                  ? toLocalTime(error.timestamp)
                  : "No disponible"}
              </Typography>
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default PlantStates;
