import { useState } from "react";
import { useEffect } from "react";
import { useDataProvider } from "react-admin";

function uuid() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

const INITIAL_STATE = {
  data: null,
  dataHash: null,
  incoming: false,
  initialized: false,
  error: false,
  throttleTimer: false,
  cancelled: false,
};

export default function useCieApi(
  callback,
  deps = [],
  { hashFunction = JSON.stringify, throttleMs = 0 } = {}
) {
  const uid = uuid();
  const DEBUG = (...a) => console.info(`${uid}:`, ...a);

  const dataProvider = useDataProvider();

  const [response, setResponse] = useState({
    data: null,
    dataHash: null,
    incoming: false,
    initialized: false,
    error: false,
    throttleTimer: false,
    cancelled: false,
  });

  const onFetch = () => {
    setResponse((prev) => {
      DEBUG("onFetch: setting incoming and throttleTimer");
      return { ...prev, incoming: true, throttleTimer: !!throttleMs };
    });

    if (throttleMs) {
      setTimeout(
        () =>
          setResponse((prev) => {
            DEBUG("onFetch: unsetting throttleTimer");
            return { ...prev, throttleTimer: false };
          }),
        throttleMs
      );
    }
  };
  const onSuccess = (response) => {
    const oldHash = response.dataHash;
    const incomingHash = hashFunction(response);
    if (incomingHash != oldHash) {
      DEBUG(new Date(), "onSuccess: new data received from API");
      setResponse(({ throttleTimer, cancelled }) => ({
        data: response,
        dataHash: incomingHash,
        incoming: false,
        initialized: true,
        error: false,
        throttleTimer,
        cancelled,
      }));
    } else {
      DEBUG(
        "onSuccess: ignoring data received from API\nReason: hash didnot change."
      );
    }
  };
  const cancellableOnSuccess = (response) => {
    if (response.cancelled) {
      onCancelled();
    } else {
      return onSuccess(response);
    }
  };

  const onError = (err) => {
    DEBUG("Error on API response. Raising\nerr:", err);
    setResponse(({ incoming, error, ...prev }) => ({
      incoming: false,
      error: true,
      ...prev,
    }));
    throw err;
  };
  const onCancelled = () => {
    DEBUG("Ignoring api response.\nReason: component detacched.");
    setResponse(({ incoming, ...prev }) => ({ incoming: false, ...prev }));
  };

  const fetchData = () => {
    DEBUG("fetchData called");
    if (response.incoming) {
      DEBUG(
        "Ignoring request data to API .\nReason: another request is pending. \ncallback:"
      );
      return;
    } else if (response.throttleTimer) {
      DEBUG(
        "Ignoring request data to API .\nReason: request is being throttled. \ncallback:"
      );
      return;
    } else {
      DEBUG("Requesting data from API");
    }

    onFetch();
    callback(dataProvider)
      .then(
        cancellableOnSuccess
        // onSuccess
      )
      .catch(onError);

    const cleanup = () => {
      if (!response.cancelled) {
        setResponse((prev) => ({
          ...prev,
          cancelled: true,
        }));
      }
    };
    return cleanup;
  };

  useEffect(fetchData, deps);

  return response;
}
