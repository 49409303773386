import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";

import Error from "../../components/Error";
import Loading from "../../components/Loading";
import { PlantStatisticsDisplay } from "../../components/Statistics/Display/PlantStatisticsDisplay";
import { ZoneStatistics } from "../../components/Statistics/Display/ZoneStatisticDisplay";
import useCieApi from "../../hooks/useCieApi";
import useCieWebSocket from "../../hooks/useCieWebSocket";
import { PlantContext } from "../../utils/Ctx";
import { ZonesContext } from "../../utils/Ctx";
import { initializeSelection } from "../../utils/ext";

function extractPlantIds(plants) {
  return JSON.stringify(plants.map(({ id }) => id));
}

const StatisticsForSelection = ({
  plantId,
  initialBlueprint,
  initialZone,
  zones,
  zoneLayouts,
}) => {
  const today = dayjs();
  const [blueprintSelector, setBlueprintSelector] = useState(initialBlueprint);
  const [zoneSelector, setZoneSelector] = useState(initialZone);
  const [dataRevision, setDataRevision] = useState(0);
  const [fromTime, setFromTime] = useState(today.subtract(1, "day"));
  const [toTime, setToTime] = useState(today);
  useCieWebSocket(
    (incoming) => {
      console.warn(
        "StatisticsForSelection, upgrading dataRevision. incoming= ",
        incoming
      );
      console.warn(
        "StatisticsForSelection, upgrading dataRevision from ",
        dataRevision
      );
      setDataRevision(dataRevision + 1);
    },
    {
      queueSize: 30,
      sendNotifications: true,
    }
  );
  return (
    <Grid
      container
      spacing={1}
      sx={{
        height: "calc(100vh - 52px)",
      }}
    >
      <Grid item xs={12} xl={8}>
        <ZoneStatistics
          plantId={plantId}
          blueprintSelector={blueprintSelector}
          setBlueprintSelector={setBlueprintSelector}
          zoneSelector={zoneSelector}
          setZoneSelector={setZoneSelector}
          zones={zones}
          zoneLayouts={zoneLayouts}
          fromTime={fromTime}
          toTime={toTime}
          setFromTime={setFromTime}
          setToTime={setToTime}
        />
      </Grid>
      <Grid item xs={12} xl={4} sx={{ mt: 4}}>
        <PlantStatisticsDisplay
          plantId={plantId}
          key={dataRevision}
          fromTime={fromTime}
          toTime={toTime}
        />
      </Grid>
    </Grid>
  );
};

const Statistics = ({ plantId }) => {
  const { zoneId } = useParams();
  const { blueprintId } = useParams();
  console.log("Statistics: Got params", blueprintId, zoneId);
  const [dataRevision, setDataRevision] = useState(0); // call setDataRevision(dataRevision + 1) to request fetch

  const zonesResponse = useCieApi(
    (dataProvider) =>
      dataProvider.getZonesData(plantId, {
        include_asset_name: false,
        include_blueprint_name: true,
      }),
    [dataRevision],
    {
      hashFunction: JSON.stringify, // TODO: maybe we could be more relaxed here and ignore some zone/mapping fields?
      throttleMs: 5000,
    }
  );

  if (!zonesResponse.initialized) {
    console.log("Statistics. LOADING");
    return <Loading />;
  }
  if (zonesResponse.error) {
    console.log("Statistics. ERROR");
    return <Error />;
  }

  const zonesData = zonesResponse.data;

  const zones = zonesData.zones;
  const zoneLayouts = zonesData.layouts;
  if (zones.length < 1 || Object.keys(zoneLayouts).length < 1) {
    return <Error />;
  }

  const selection = initializeSelection(zoneId, zoneLayouts, zones);

  return (
    <ZonesContext.Provider value={zones}>
      <StatisticsForSelection
        plantId={plantId}
        zones={zones}
        zoneLayouts={zoneLayouts}
        initialBlueprint={selection.blueprintId}
        initialZone={selection.zoneId}
      />
    </ZonesContext.Provider>
  );
};

const PlantStatisticsForPlant = ({ plantId }) => {
  const [plantIdContext, setPlantIdContext] = useState(plantId);
  return (
    <PlantContext.Provider value={[plantIdContext, setPlantIdContext]}>
      <Statistics plantId={plantId} />
    </PlantContext.Provider>
  );
};

const PlantStatisticsWithoutPlant = () => {
  const [dataRevision, setDataRevision] = useState(0); // call setDataRevision(dataRevision + 1) to request fetch

  const plantsResponse = useCieApi(
    (dataProvider) => dataProvider.getStatusData(),
    [dataRevision],
    {
      hashFunction: extractPlantIds,
      throttleMs: 5000,
    }
  );
  if (!plantsResponse.initialized) {
    return <Loading />;
  }

  if (plantsResponse.error) {
    return <Error />;
  }

  return <PlantStatisticsForPlant plantId={plantsResponse.data[0].id} />;
};

const PlantStatistics = () => {
  const { plantId } = useParams();

  if (plantId == undefined) {
    return <PlantStatisticsWithoutPlant />;
  }
  return <PlantStatisticsForPlant plantId={plantId} />;
};

export default PlantStatistics;
