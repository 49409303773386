import dataProvider from "./utils/dataProvider";

function requestReload(release) {
  if (window["runtimeConfig"].REACT_APP_RELEASE !== release) {
    console.log("should reload. checking api availability...");
    dataProvider.ping().then(() => {
      console.log("Reloading!");
      window.location.reload(true);
    });
  }
}

const refreshNewClient = () => {
  setInterval(() => dataProvider.get_version().then(requestReload), 10000);
};

export default refreshNewClient;
