import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { forwardRef, useContext, useEffect } from "react";

import { AlertContext } from "../utils/AlertProvider";
import { eventMapping } from "../utils/consts";
import { EventTypes } from "../utils/consts";
import AlarmNotification from "./AlarmNotification";
import { getSeverity } from "./Statistics/EventLog/Row";

/**
 * A notification component for displaying event logs.
 */
export const EventLogNotification = forwardRef((props, ref) => {
  const { event, handleClose, color, ...rest } = props;

  const eventType = Object.entries(EventTypes).find(([key, val]) =>
    val.includes(event.event_type)
  )[0];
  const eventStatus = eventMapping[event.event_type].status[event.status];

  const title =
    event.event_type === "Alarma Activa"
      ? `${eventStatus} ${eventType}`
      : eventMapping[event.event_type].title;
  const details =
    event.event_type === "Alarma Activa"
      ? event.status !== "OK"
        ? `${event.zone.slice(0,24)}: ${event.temperature} °C`
        : `${event.zone}`
      : `${eventStatus}`;
  const severity = getSeverity(event);
  const timestamp = new Date(event.timestamp)
    .toLocaleString("es-cl")
    .replace(", ", " ");

  if (!title) {
    console.warn(
      "option",
      event,
      "not found or has no title for eventMapping",
      eventMapping
    );
  }

  return (
    <Alert
      ref={ref}
      severity={severity}
      color={severity}
      variant={props.variant ? props.variant : "outlined"}
      sx={{
        "& .MuiAlert-icon": {
          fontSize: 20,
        },
        border: 3,
        borderRadius: 1,
        width: "100%",
        borderColor: props.color ? props.color : "background.main",
        backgroundColor: "background.default",
      }}
      onClose={handleClose}
      {...rest}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={2} sx={{ pr: 2 }}>
        <Typography variant="body2">{timestamp}</Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography variant="body2">{details}</Typography>
      </Stack>
    </Alert>
  );
});

export default function NotificationManager(props) {
  const { state, actions } = useContext(AlertContext);
  return (
    <>
      <UrgentNotifications state={state} actions={actions} />
      <NonUrgentNotifications state={state} actions={actions} />
    </>
  );
}

function Notification({ duration = 10000, alert, actions }) {
  const handleClose = () => {
    console.log(
      "Closing",
      alert.event_type,
      alert.status,
      new Date().getTime()
    );
    actions.removeEvent({ ...alert, urgency: { name: "" } });
  };

  useEffect(() => {
    const timer = setTimeout(() => handleClose(alert), duration);
    console.log(
      "Setting timer",
      alert.event_type,
      alert.status,
      new Date().getTime()
    );
    return () => {
      clearTimeout(timer);
    };
  }, [alert, duration]);
  const severity = getSeverity(alert);
  return (
    <Box
      sx={{
        mb: 1,
      }}
    >
      <EventLogNotification
        event={alert}
        severity={severity}
        handleClose={handleClose}
      />
    </Box>
  );
}

export const NonUrgentNotifications = ({ state, actions, ...props }) => {
  if (!state?.alerts?.length) {
    return null;
  }
  return (
    <Box
      sx={{
        position: "absolute",
        right: "16px",
        bottom: "16px",
        zIndex: 2000,
      }}
    >
      {state.alerts.map((alert, index) => (
        <Notification key={index} alert={alert} actions={actions} {...props} />
      ))}
    </Box>
  );
};

export const UrgentNotifications = ({ state, actions, ...props }) => {
  if (!state?.alarms?.length) {
    return null;
  }
  return state?.alarms.map((alarm, index) => (
    <AlarmNotification key={index} alarm={alarm} actions={actions} {...props} />
  ));
};
