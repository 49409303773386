import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Info = ({ text, ...props }) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
      }}
    >
      <InfoOutlinedIcon fontSize="large" color="info" sx={{ mb: 1 }} />
      <Typography variant={"h5"}>{text}</Typography>
    </Box>
  );
};

export default Info;
