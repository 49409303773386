import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { alpha } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { yellow } from "@mui/material/colors";
import { memo, useCallback, useState } from "react";
import { useRedirect } from "react-admin";

const WarningMessage = (props) => {
  const redirect = useRedirect();
  return (
    <Grid
      container
      direction={"row"}
      sx={{
        width: "450px",
        backgroundColor:
          props.alarm.severity == "alarm" ? red[600] : yellow[800],
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        item
        xs={2}
        sx={{
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <Grid item>{props.icon}</Grid>
      </Grid>
      <Grid
        container
        item
        direction={"column"}
        xs={10}
        sx={{
          backgroundColor: "background.default",
          alignItems: "left",
          justifyContent: "center",
          pl: 2,
        }}
      >
        <Grid
          item
          sx={{
            my: 3,
            mx: 1,
          }}
        >
          <Typography variant="h3" align="center">
            {props.title}
          </Typography>
          <Typography variant="h5" align="center">
            Temperatura
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            mb: 2,
            px: 3,
          }}
        >
          <Typography variant="body">{props.timestamp}</Typography>
        </Grid>
        <Grid
          item
          sx={{
            px: 3,
          }}
        >
          <Typography variant="h6">Zona: {props.alarm.zone}</Typography>
        </Grid>
        <Grid
          item
          sx={{
            px: 3,
            mb: 2,
          }}
        >
          <Typography variant="h6">
            Temperatura: {props.alarm.temperature} °C
          </Typography>
        </Grid>
        <Grid
          container
          item
          sx={{
            my: 1,
            backgroundColor: "background.default",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Grid item>
            <Button
              variant={"contained"}
              color={"error"}
              onClick={props.handleClose}
            >
              Cerrar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={"contained"}
              color={"success"}
              onClick={() => {
                props.setOpen(false);
                redirect(`/realtime/${props.plant_id}/${props.zone_id}`);
                window.location.reload();
              }}
              autoFocus
            >
              Ver zona
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Modal = ({ alarm, open, handleClose, setOpen }) => {
  const backgroundColor =
    alarm.severity == "alarm"
      ? alpha(red[600], 0.25)
      : alpha(yellow[800], 0.25);
  const title = alarm.severity == "alarm" ? "Alarma" : "Pre-Alarma";
  const icon =
    alarm.severity == "alarm" ? <ErrorOutlineIcon /> : <WarningAmberIcon />;
  const timestamp = new Date(alarm.timestamp);
  const timestampString = `El día
            ${
              timestamp.toDateString() == new Date().toDateString()
                ? "de Hoy"
                : timestamp.toLocaleDateString("es-cl")
            }
            a las
            ${timestamp.toLocaleTimeString("es-cl")}.`;
  return (
    <Dialog
      keepMounted
      PaperProps={{
        sx: {
          backgroundColor: alarm.severity == "alarm" ? "red" : "green",
          color: "white",
          // borderRadius: 3,
        },
      }}
      open={open}
      onClose={handleClose}
      sx={{
        backgroundColor: { backgroundColor },
        borderRadius: 3,
      }}
    >
      <WarningMessage
        title={title}
        timestamp={timestampString}
        alarm={alarm}
        setOpen={setOpen}
        icon={icon}
        zone_id={alarm.zone_id}
        plant_id={alarm.plant_id}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

const AlarmNotification = ({ alarm, actions, ...props }) => {
  const [open, setOpen] = useState(true);
  const handleClose = useCallback(
    (alarm) => {
      actions.removeEvent({ ...alarm, urgency: { name: "URGENT" } });
      setOpen(false);
    },
    [open]
  );
  return (
    <Modal
      open={open}
      alarm={alarm}
      actions={actions}
      setOpen={setOpen}
      handleClose={(action, reason) => {
        if (reason && reason == "backdropClick") {
          //Disable outside click
          return;
        }
        handleClose(alarm);
      }}
      {...props}
    />
  );
};

export default memo(AlarmNotification);
