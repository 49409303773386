import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { useRedirect } from "react-admin";

import useCieApi from "../../hooks/useCieApi";
import "../../styles.css";
import {
  backgroundColors,
  borderColors,
  stateIcons,
  COLORRANGE,
} from "../../utils/consts";
import { getStatusDataHashIgnoringWorkerTimestamps } from "../../utils/dataProvider";
import { toLocalTime } from "../../utils/ext";
import Error from "../Error";
import Loading from "../Loading";
import { ColorScaleHeader } from "../Statistics/StatsHeader";

export function dateToDateString(datetime) {
  const date = new Date(datetime);
  return date.toISOString().split("T")[0];
}

function buildZoneOptions(zoneLayouts, blueprintId, zoneIds) {
  console.debug(
    "[RealTimeHeader] buildZoneOptions ",
    zoneLayouts,
    blueprintId,
    zoneIds
  );
  const assetNames = Array.from(
    new Set(zoneLayouts[blueprintId].map((zone) => zone.parent_asset))
  );
  const assetValues = assetNames.reduce((acc, curr) => {
    acc[curr] = zoneLayouts[blueprintId]
      .filter((zone) => zone.parent_asset === curr)
      .map((zone) => zone.id);
    return acc;
  }, []);
  console.debug("[RealTimeHeader] assetNames", assetNames);
  console.debug("[RealTimeHeader] assetValues", assetValues);
  const allZonesOption = (
    <MenuItem
      key={"Todas"}
      value={zoneLayouts[blueprintId].map((zone) => zone.id)}
    >
      <Typography variant="h6" align="justify">
        Todas
      </Typography>
    </MenuItem>
  );
  const zoneOptions = Object.keys(assetValues).map((asset) => {
    return (
      <MenuItem key={asset} value={assetValues[asset]}>
        <Checkbox
          color="primary"
          checked={assetValues[asset].every((zone) => zoneIds.includes(zone))}
        />
        <ListItemText primary={asset} />
      </MenuItem>
    );
  });
  return [allZonesOption, ...zoneOptions];
}

const StatusIcon = ({ state }) => {
  const redirect = useRedirect();
  const handleClick = (event) => {
    redirect(`/stats?filterLogType=${state.name}`);
  };
  return (
    <Tooltip
      title={
        <>
          <Typography variant="caption">
            [{state.name}] Actualizado: {toLocalTime(state.value.LastPoll)}
          </Typography>
        </>
      }
    >
      <IconButton
        sx={{
          ":hover": {
            bgcolor: "white",
            color: "text.dark",
          },
          border: 1,
          borderRadius: 1,
          color: "text.dark",
          borderColor: borderColors[state.value.STATUS],
          backgroundColor:
            backgroundColors[state.value.STATUS] ?? backgroundColors["Default"],
        }}
        onClick={handleClick}
      >
        {stateIcons[state.name][state.value.STATUS]}
      </IconButton>
    </Tooltip>
  );
};

const Status = ({ plantId, dataRevision }) => {
  const statusResponse = useCieApi(
    (dataProvider) =>
      dataProvider
        .getStatusData()
        .then((plants) => plants.filter((plant) => plant.id == plantId)),
    [dataRevision, plantId],
    {
      hashFunction: getStatusDataHashIgnoringWorkerTimestamps,
      throttleMs: 5000,
    }
  );

  if (!statusResponse.initialized) {
    return <Loading />;
  }

  if (statusResponse.error) {
    return <Error />;
  }
  console.debug("Got status response", statusResponse);
  const status = statusResponse.data;

  const cards = status[0].states.map(
    // TODOC why only the first?
    (state) => <StatusIcon state={state} key={state.name} />
  );
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="center"
      alignItems={"center"}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "45px",
      }}
    >
      {cards}
    </Stack>
  );
};

export const OverallHeader = (props) => {
  const { plantId, statusDataRevision } = props;

  return (
    <>
      {/* {statusCards} */}
      <Typography>Actualizado: {new Date().toLocaleString()}</Typography>
    </>
  );
};

export const RealTimeHeader = (props) => {
  const {
    plantId,
    blueprintId,
    setBlueprintId,
    zoneIds,
    traces,
    setZoneIds,
    zoneLayouts,
    colorScaleValues,
    setColorScaleValues,
    statusDataRevision,
  } = props;
  const [zoneOptions, setZoneOptions] = useState(null);
  const [blueprintOptions, setBlueprintOptions] = useState(null);

  console.debug("[RealTimeHeader] blueprintId ", blueprintId);
  console.debug("[RealTimeHeader] traces ", traces);
  console.debug("[RealTimeHeader] zoneIds ", zoneIds);

  //Initialize zone Ids
  useEffect(() => {
    if (zoneIds === null && zoneLayouts !== null && blueprintId !== null) {
      const newZoneIds = zoneLayouts[blueprintId].map((zone) => zone.id);
      setZoneIds(newZoneIds);
    }
  }, [blueprintId, zoneLayouts, zoneIds]);

  useEffect(() => {
    if (zoneLayouts !== null && blueprintId !== null && zoneIds !== null) {
      const newZoneOptions = buildZoneOptions(
        zoneLayouts,
        blueprintId,
        zoneIds
      );
      setZoneOptions(newZoneOptions);
    }
  }, [blueprintId, zoneLayouts, zoneIds]);

  useEffect(() => {
    if (colorScaleValues === null && traces !== null) {
      const min = parseInt(
        Math.min(...Object.values(traces).map((trace) => trace.MIN_TEMP)) - 3
      );
      const max = parseInt(
        Math.max(...Object.values(traces).map((trace) => trace.MAX_TEMP)) + 3
      );
      console.debug("[RealTimeHeader] setting colorscale values ", min, max);
      setColorScaleValues([min, max]);
    }
  }, [traces]);

  useEffect(() => {
    const newBlueprintOptions = Object.keys(zoneLayouts).map((blueprintId) => {
      return (
        <MenuItem key={blueprintId} value={blueprintId}>
          {zoneLayouts[blueprintId][0].blueprint_name}
        </MenuItem>
      );
    });
    setBlueprintOptions(newBlueprintOptions);
  }, [blueprintId]);

  if (zoneOptions === null || blueprintOptions === null) {
    return <Loading height={"calc(100vh - 52px)"} />;
  }

  // console.warn("[RealTimeHeader] zoneOptions", zoneOptions);
  return (
    <>
      <Grid
        container
        direction={"row"}
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          my: 2,
        }}
      >
        <Grid item xs={12} md={2}>
          <Stack direction="row" justifyContent="space-evenly">
            <FormControl>
              <InputLabel id="zone-id-label">Piso</InputLabel>
              <Select
                labelId="zone-id-label"
                value={blueprintId}
                label="Piso"
                onChange={(event) => {
                  const newBluePrintId = parseInt(event.target.value);
                  const newZoneIds = zoneLayouts[newBluePrintId].map(
                    (zone) => zone.id
                  );
                  setBlueprintId(newBluePrintId);
                  setZoneIds(newZoneIds);
                }}
              >
                {blueprintOptions}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="zone-id-label">Zonas</InputLabel>
              <Select
                multiple
                value={zoneIds}
                label="Zona"
                renderValue={() => "Seleccionar"}
                //   selected
                //     .map(
                //       (zId) =>
                //         zoneLayouts[blueprintId].filter(
                //           (zone) => zone.id == zId
                //         )[0].name
                //     )
                //     .join(", ")
                // }
                onChange={(event) => {
                  const selectedEvent =
                    event.target.value[event.target.value.length - 1];
                  const newZoneIds = selectedEvent.every((zone) =>
                    zoneIds.includes(zone)
                  )
                    ? zoneIds.filter(
                        (zoneId) => !selectedEvent.includes(zoneId)
                      )
                    : [...zoneIds, ...selectedEvent];
                  setZoneIds(newZoneIds);
                }}
              >
                {zoneOptions}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={8}>
          <ColorScaleHeader
            colorScaleValues={colorScaleValues}
            setColorScaleValues={setColorScaleValues}
            colorRange={COLORRANGE}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Box justifyContent={"center"} alignItems={"left"}>
            <Status plantId={plantId} dataRevision={statusDataRevision} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
