import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useAuthProvider, useGetIdentity } from "react-admin";
import useWebSocket from "react-use-websocket";

import { AlertContext } from "../utils/AlertProvider";

const DEBUG = (...a) => console.debug("WS:", ...a);

const READY_STATE = {
  UNINSTANTIATED: -1,
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

const CHART = {
  [READY_STATE.CONNECTING]: "Connecting",
  [READY_STATE.OPEN]: "Open",
  [READY_STATE.CLOSING]: "Closing",
  [READY_STATE.CLOSED]: "Closed",
  [READY_STATE.UNINSTANTIATED]: "Uninstantiated",
};

const WS_LOC = window["runtimeConfig"].REACT_APP_REALTIME_WS;

class Queue {
  constructor(maxlen) {
    this.items = [];
    this.maxlen = maxlen;
  }

  enqueue(element) {
    if (this.items.length >= this.maxlen) {
      this.dequeue();
    }
    this.items.push(element);
    return this;
  }

  dequeue() {
    if (this.isEmpty) {
      DEBUG("Queue is empty");
      return;
    }
    return this.items.shift();
  }

  includes() {}
  get front() {
    if (this.isEmpty) {
      return "Queue is empty";
    }
    return this.items[0];
  }

  get recent() {
    if (this.isEmpty) {
      return "Queue is empty";
    }
    return this.items[this.items.length - 1];
  }

  get isEmpty() {
    return this.length === 0;
  }

  get length() {
    return this.items.length;
  }
}

/**
 * attemptNumber will be 0 the first time it attempts to reconnect, so this equation
 * results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and
 * then caps at 10 seconds until the maximum number of attempts is reached
 * @param {*} attemptNumber
 * @returns
 */
function exponentialBackoff(attemptNumber) {
  return Math.min(Math.pow(2, attemptNumber) * 1000, 10000);
}

export const useCieWebSocket = (
  onNewData = console.log,
  {
    onRepeatedData = () => DEBUG("Ignoring repeated data"),
    queueSize = 10,
    sendNotifications = true,
  } = {}
) => {
  const [user, setUser] = useState(null)
  const { actions } = useContext(AlertContext);
  const authProvider = useAuthProvider();
  const identity = useGetIdentity();
  const [messageHistory, setMessageHistory] = useState(new Queue(queueSize));
  const [clientId, _] = useState(Date.now());
  const socketUrl = `${WS_LOC}/${clientId}`;
  DEBUG(
    "messageHistory",
    "length",
    messageHistory.length,
    "front",
    messageHistory.front,
    "recent",
    messageHistory.recent,
    messageHistory.items
  );
  const token = authProvider.getToken();
  const { lastMessage, readyState } = useWebSocket(socketUrl, {
    queryParams: { token },
    reconnectAttempts: Infinity,
    reconnectInterval: exponentialBackoff,
    retryOnError: true,
    shouldReconnect: () => true,
  });
  useEffect(()=>{
    if (!identity.isLoading){
      setUser(identity.data.fullName)
    }
  }, [identity])
  useEffect(() => {
    if (lastMessage !== null) {
      const data = lastMessage.data;
      const previousHash = messageHistory.recent;
      const parsed = JSON.parse(data);
      parsed.user = user;
      const currentHash = JSON.stringify(parsed);
      DEBUG("previousHash", previousHash);
      DEBUG("currentHash", currentHash);

      if (currentHash != previousHash) {
        setMessageHistory((q) => q.enqueue(currentHash));
        onNewData(parsed);
        if (sendNotifications) {
          actions.addEvent(parsed);
        }
      } else {
        onRepeatedData(parsed);
      }
    }
  }, [lastMessage, onNewData, onRepeatedData]);

  useEffect(() => {
    DEBUG("connectionStatus changed to ", CHART[readyState]);
  }, [readyState]);
};

export default useCieWebSocket;
