import { useLocation } from "react-router-dom";

export const API_URL = window["runtimeConfig"].REACT_APP_CIE_API_URL_ADDRESS;
export const API_URL_v1 = `${API_URL}/v1`;
export const API_TOKEN_URL =
  window["runtimeConfig"].REACT_APP_CIE_API_TOKEN_ENDPOINT;

const DEBUG = (...a) => console.debug("authProvider:", ...a);

function jsonOrRaise(response) {
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json();
}

export function getToken() {
  const auth = localStorage.getItem("auth");
  if (!auth) {
    return undefined;
  }
  return JSON.parse(auth).access_token;
}
export function getBearerToken() {
  const token = getToken();
  if (!token) {
    return undefined;
  }
  return `Bearer ${token}`;
}

function getUser() {
  const bearerToken = getBearerToken();
  if (!bearerToken) {
    return Promise.reject();
  }
  const request = new Request(`${API_URL_v1}/user/me`, {
    method: "GET",
    headers: new Headers({
      accept: "application/json",
      Authorization: bearerToken,
    }),
  });
  return fetch(request)
    .then(jsonOrRaise)
    .then((apiUser) => {
      const id = apiUser.id;
      const fullName = `${apiUser.first_name} ${apiUser.last_name}`;
      return Promise.resolve({
        id: id,
        fullName: fullName,
        plant_id: apiUser.plant_id,
      });
    })
    .catch((error) => {
      console.warn("Failed to retrieve current user.");
      localStorage.removeItem("auth"); // TODO: maybe we should ping backend with auth in checkAuth instead?
      return Promise.reject(error);
    });
}

function checkTokenValidity() {
  const bearerToken = getBearerToken();
  if (!bearerToken) {
    return Promise.reject();
  }
  const request = new Request(`${API_URL}/auth/`, {
    method: "HEAD",
    headers: new Headers({
      accept: "application/json",
      Authorization: bearerToken,
    }),
  });
  return fetch(request)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return Promise.resolve();
    })
    .catch((err) => {
      console.warn("Invalid Token", err);
      return Promise.reject("Please sign in again");
    });
}

export const authProvider = {
  login: ({ username, password }) => {
    DEBUG("login");
    const body = { username: username, password: password };
    const request = new Request(API_TOKEN_URL, {
      method: "POST",
      body: new URLSearchParams(body),
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    });
    return fetch(request)
      .then(jsonOrRaise)
      .then((auth) => {
        localStorage.setItem("auth", JSON.stringify(auth));
        return Promise.resolve();
      })
      .catch((error) => {
        console.warn(error);
        throw new Error("Failed login");
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status == 401 || status == 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const url = window.location;
    const hash = url.hash;
    for (let accepted of ["#/resetPassword", "#/createPassword"]) {
      if (hash.startsWith(accepted)) {
        DEBUG("checkAuth, safe url:", url.hash);
        return Promise.resolve();
      }
    }

    const auth = localStorage.getItem("auth");
    if (!auth) {
      DEBUG("checkAuth: no auth");
      return Promise.reject();
    }
    return checkTokenValidity();
  },
  logout: () => {
    DEBUG("logout");
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  getIdentity: () => getUser(),
  handleCallback: () => {
    DEBUG("handleCallback!");
    return Promise.resolve();
  },
  getPermissions: ({ ...a }) => {
    DEBUG("getPermissions");
    return Promise.resolve([]);
  },
  getToken: () => getToken(),
  getBearerToken: () => getBearerToken(),
};
