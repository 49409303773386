import React, { createContext, useReducer } from "react";

import dataProvider from "./dataProvider";

// fixme useDataprovider wont work outside app

const AlertContext = createContext([{}, () => {}]);

const initialState = {
  alerts: [],
  alarms: [],
};

const StatusQueue = []; //FIXME cap size

const MAX_CONCURRENT_NOTIFICATIONS = 10;

function computeHash(event) {
  const identifier = `${event.timestamp}_${event.status}_${event.event_type}_${event.info}`;
  return identifier;
}

function handleAlertUpdate(state, alert) {
  const incomingHash = computeHash(alert);
  let updatedState = state.alerts;
  if (!StatusQueue.includes(incomingHash)) {
    //Check if hash is in current state hashes and add only if it's new
    updatedState = [...(state?.alerts || []), alert];
    StatusQueue.push(incomingHash); // TODO check if queue has been filled
  }
  if (updatedState?.length > MAX_CONCURRENT_NOTIFICATIONS) {
    return updatedState.slice(-MAX_CONCURRENT_NOTIFICATIONS);
  }
  return updatedState;
}

function handleAlarmUpdate(state, alarm) {
  const incomingHash = computeHash(alarm);
  let updatedState = state.alarms;
  if (!StatusQueue.includes(incomingHash)) {
    //Check if hash is in current state hashes and add only if it's new
    updatedState = [...(state?.alarms || []), alarm];
    StatusQueue.push(incomingHash); // TODO check if queue has been filled
  }
  if (updatedState?.length > MAX_CONCURRENT_NOTIFICATIONS) {
    return updatedState.slice(-MAX_CONCURRENT_NOTIFICATIONS);
  }
  return updatedState;
}

let reducer = (state, action) => {
  let newAlerts, newAlarms, element;

  switch (action.type) {
    case "ADD_ALERT":
      newAlerts = handleAlertUpdate(state, action.payload.event_data);
      return { ...state, alerts: newAlerts };
    case "ADD_ALARM":
      newAlarms = handleAlarmUpdate(state, {
        id_: action.payload.id_ || null,
        user: action.payload.user,
        ...action.payload.event_data,
      });
      return { ...state, alarms: newAlarms };
    case "REMOVE_ALERT":
      newAlerts = state.alerts.filter(
        // TODO: shouldn't we use hashes instead of timestamps?
        (e) => e.timestamp !== action.payload.timestamp
      );
      return { ...state, alerts: newAlerts };
    case "REMOVE_ALARM":
      newAlarms = state.alarms.filter(
        // TODO: shouldn't we use hashes instead of timestamps?
        (e) => e.timestamp !== action.payload.timestamp
      );
      return { ...state, alarms: newAlarms };
    default:
      console.log("No valid action: " + action.type);
      return state;
  }
};

function AlertContextProvider(props) {
  let [state, dispatch] = useReducer(reducer, initialState);

  const dispatchRemoveAlarm = (name, payload) => {
    dispatch({
      type: name,
      payload,
    });
  };
  const actions = {
    addEvent: (payload) => {
      dispatch({
        type: payload.urgency.name == "URGENT" ? "ADD_ALARM" : "ADD_ALERT",
        payload,
      });
    },
    removeEvent: (payload) => {
      console.debug("removeEvent, payload=", payload);
      if (payload.urgency.name !== "URGENT") {
        return dispatchRemoveAlarm("REMOVE_ALERT", payload);
      }

      const { id_, plant_id, event_type, user } = payload;
      console.debug(
        "REMOVE_ALARM: id_, plant_id, event_type",
        id_,
        plant_id,
        event_type
      );
      dataProvider
        .hideEvent(id_, plant_id, user, {event_type, target: "modal"})
        .then(() => dispatchRemoveAlarm("REMOVE_ALARM", payload));
    },
  };

  return (
    <AlertContext.Provider value={{ state: state, actions: actions }}>
      {props.children}
    </AlertContext.Provider>
  );
}

export { AlertContext, AlertContextProvider };
