import LockIcon from "@mui/icons-material/Lock";
import { Avatar } from "@mui/material";
import { Button } from "@mui/material";
import { CardContent } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form } from "ra-core";
import { required } from "ra-core";
import { useTranslate } from "ra-core";
import { useNotify } from "ra-core";
import { useSafeSetState } from "ra-core";
import * as React from "react";
import { useDataProvider } from "react-admin";
import { useRedirect } from "react-admin";
import { TextInput } from "react-admin";
import { PasswordInput } from "react-admin";
import { useLocation } from "react-router-dom";

import Deploying from "./Deploying";
import { LoginBase } from "./Login/Login";

const REDIRECT_TIMEOUT_MS = 7 * 1000;

const LoginForm = (props) => {
  const { onSubmit, className, hideUserInput, extraInputs, actionText, title } =
    props;
  const [loading, setLoading] = useSafeSetState(false);
  // const login = useLogin();
  const translate = useTranslate();

  const userInput = hideUserInput ? null : (
    <TextInput
      autoFocus
      source="username"
      label={"Correo"}
      type="email"
      autoComplete="username"
      color="primary"
      validate={required()}
      fullWidth
      sx={{
        input: {
          color: "background.light",
        },
        "& .MuiTextField-root": {
          border: "1px solid",
          borderColor: "background.light",
        },
        "& .MuiInputLabel-root": {
          fontSize: 20,
          color: "background.light",
        },
        "&. label": {
          "&.Mui-focused": {
            color: "error",
          },
        },
      }}
    />
  );
  return (
    <StyledForm
      onSubmit={(values) => onSubmit({ values, loading, setLoading })}
      mode="onChange"
      noValidate
      className={className}
    >
      <CardContent className={LoginFormClasses.content}>
        <Typography
          variant="h5"
          align="center"
          color={"background.light"}
          sx={{ mb: 5 }}
        >
          Reestablecer Contraseña
        </Typography>
        {userInput}
        {extraInputs}
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          disabled={loading}
          fullWidth
          className={LoginFormClasses.button}
          sx={{
            backgroundColor: "primary.main",
          }}
        >
          {loading ? (
            <CircularProgress
              className={LoginFormClasses.icon}
              size={19}
              thickness={3}
            />
          ) : (
            actionText
          )}
        </Button>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = "RaLoginForm";

const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 400,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

//

export const PasswordReset = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const onSubmit = ({ values, loading, setLoading }) => {
    setLoading(true);
    dataProvider
      .resetPassword(values)
      .then(() => {
        setLoading(false);
        notify(
          `Correo enviado a ${values.username} - Redirigiendo al login...`,
          { type: "success" }
        );
        setTimeout(() => redirect("/login"), REDIRECT_TIMEOUT_MS);
        //
      })
      .catch((error) => {
        setLoading(false);
        console.warn(error);
        notify(error, {
          type: "error",
        });
      });
  };
  return (
    <LoginBase
      backgroundImage="img/LoginBackground.png"
      logoImage="img/LogoWhite.png"
      loginForm={<LoginForm onSubmit={onSubmit} actionText="Enviar link" />}
      avatar={
        <Avatar sx={{ backgroundColor: "primary.light" }}>
          <LockIcon sx={{ backgroundColor: "primary.light" }} />
        </Avatar>
      }
      onAuth={() => {}}
    />
  );
};

function validatePasswords(message = "Contraseñas no coinciden") {
  return (value, allValues) => {
    return allValues.password == value ? undefined : message;
  };
}
export const PasswordCreate = () => {
  const [currentUser, setCurrentUser] = useSafeSetState("");

  const translate = useTranslate();
  const location = useLocation();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const onSubmit = ({ values, loading, setLoading }) => {
    setLoading(true);
    dataProvider
      .changePassword(values)
      .then(() => {
        setLoading(false);
        notify(
          `Contraseña actualizada con éxito. Puede cerrar esta ventana...`,
          { type: "success" }
        );
        setTimeout(() => redirect("/login"), REDIRECT_TIMEOUT_MS);
        //
      })
      .catch((error) => {
        setLoading(false);
        console.warn(error);
        notify(error, {
          type: "error",
        });
        setTimeout(() => redirect("/resetPassword"), REDIRECT_TIMEOUT_MS);
      });
  };

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  if (!token) {
    notify("Acceso inválido - por favor intentar nuevamente", {
      type: "error",
    });
    setTimeout(() => redirect("/#/login"), 5 * 1000);
    return <Deploying text="" />;
  } else {
    dataProvider
      .checkPassword({ token: token })
      .then((user) => {
        setCurrentUser(`${user.first_name} ${user.last_name}`);
      })
      .catch((error) => {
        console.warn(error);
        notify(error, {
          type: "error",
        });
      });
  }
  const extraInputs = (
    <>
      <TextInput
        disabled
        source="token"
        sx={{ display: "none" }}
        type="hidden"
        defaultValue={token}
        fullWidth
      />
      <PasswordInput
        source="password"
        label={"Nueva " + translate("ra.auth.password").toLowerCase()}
        autoComplete="current-password"
        validate={required()}
        fullWidth
        sx={{
          input: {
            color: "primary.light",
          },
          "& .MuiTextField-root": {
            border: "1px solid",
            borderColor: "background.light",
          },
          "& .MuiInputLabel-root": {
            fontSize: 20,
            color: "background.light",
          },
          "&. label": {
            "&.Mui-focused": {
              color: "error",
            },
          },
        }}
      />
      <PasswordInput
        source="confirmPassword"
        label={"Confirmar nueva " + translate("ra.auth.password").toLowerCase()}
        validate={[required(), validatePasswords()]}
        fullWidth
        sx={{
          input: {
            color: "primary.light",
          },
          "& .MuiTextField-root": {
            border: "1px solid",
            borderColor: "background.light",
          },
          "& .MuiInputLabel-root": {
            fontSize: 20,
            color: "background.light",
          },
          "&. label": {
            "&.Mui-focused": {
              color: "error",
            },
          },
        }}
      />
    </>
  );

  const loginForm = (
    <LoginForm
      hideUserInput={true}
      onSubmit={onSubmit}
      actionText="Crear Contraseña"
      extraInputs={extraInputs}
      title={currentUser}
    />
  );
  return (
    <LoginBase
      backgroundImage="img/LoginBackground.png"
      logoImage="img/LogoWhite.png"
      loginForm={loginForm}
      avatar={
        <Avatar sx={{ backgroundColor: "primary.light" }}>
          <LockIcon sx={{ backgroundColor: "primary.light" }} />
        </Avatar>
      }
      onAuth={() => {
        console.log("onAuth!");
      }}
    />
  );
};
