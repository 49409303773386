import Grid from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const Loading = ({ text, ...props }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        direction: "row",
      }}
    >
      <Grid
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
        item
      >
        <CircularProgress color="secondary" />
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {text ? text : "Cargando datos..."}
        </Typography>
        <Grid />
      </Grid>
    </Grid>
  );
};

export default Loading;
