import { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";

import Error from "../components/Error";
import Loading from "../components/Loading";
import { DataProvider } from "../utils/dataProvider";

/**
 * @typedef {Object} Response<Outcome>
 * @property {Outcome} data - data.
 * @property {bool} error - error.
 * @property {bool} loading - loading.
 * @template Outcome
 */

/**
 * Use react's useEffect to return {data, error, loading} from dataProvider
 *
 * @param {function(DataProvider):Promise<Outcome>} callback - A callback fetch data from api.
 * @param {string[]} deps - to be used for react's useeffect.
 * @returns {Response<Outcome>}
 * @template Outcome
 */
export default function useData(
  callback,
  { pollMs, name, initial, deps } = {}
) {
  const [data, setData] = useState(initial == undefined ? [] : initial);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const dataProvider = useDataProvider();

  const rawEffect = () => {
    return callback(dataProvider)
      .then((response) => {
        setError(false);
        setLoading(false);
        setData(response);
      })
      .catch((err) => {
        console.log("ERROR: callback ", callback, err);
        setLoading(false);
        setError(true);
      });
  };

  let timeoutId;
  let wrap;
  if (pollMs === undefined) {
    wrap = () => {
      rawEffect();
    };
  } else {
    wrap = () => {
      rawEffect()
        .then((r) => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(wrap, pollMs);
          return r;
        })
        .catch((err) => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(wrap, pollMs);
          throw err;
        });
      return () => {
        clearTimeout(timeoutId);
      };
    };
  }

  /* TODO: conditional rendering
  
  Instead of triggering on deps, we should force re-trigger the rawEffect on deps change,
  But only re-render when any of [data,error,loading] chagnes
  */
  useEffect(wrap, [
    //data,  // TODO: conditional rendering
    error, // TODO: conditional rendering
    loading, // TODO: conditional rendering
    ...(deps || []),
  ]);

  return {
    data,
    error,
    loading,
    setData,
    setLoading,
    setError,
  };
}

/**
 *
 * @param {function} callback - funciton to compute a state
 * @param {*} initial
 * @param {*} deps
 * @returns
 */
export function useCompute(callback, deps, initial) {
  // const {deps, initialValue} = props;
  const [data, setData] = useState(initial);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      const response = callback();
      setData(response);
      setLoading(false);
    } catch (err) {
      console.log("ERROR: callback ", callback, err);
      setLoading(false);
      setError(true);
    }
  }, deps || []);
  return {
    data: data,
    error: error,
    loading: loading,
  };
}

export const LazyLoadedComponent = ({
  computer,
  builder,
  deps,
  LoadingComponent,
  ErrorComponent,
  useComputer,
}) => {
  if (useComputer == undefined) {
    useComputer = useCompute;
  }

  const response = useComputer(computer, deps, undefined);
  if (response.loading) {
    LoadingComponent = LoadingComponent || Loading;
    return <LoadingComponent />;
  }

  if (response.error) {
    ErrorComponent = ErrorComponent || Error;
    return <ErrorComponent />;
  }
  const ret = builder(response.data);
  return ret;
};

export const LazyLoadedDataComponent = ({
  name,
  computer,
  pollMs,
  ...props
}) => {
  const dataProvider = useDataProvider();
  const dataComputer = () => computer(dataProvider);
  let useComputer = (callback, deps, initial) =>
    useData(callback, { pollMs, name, deps, initial });
  return (
    <LazyLoadedComponent
      computer={dataComputer}
      useComputer={useComputer}
      {...props}
    />
  );
};
