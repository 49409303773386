import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { Title } from "react-admin";

import Error from "../../components/Error";
import Loading from "../../components/Loading";
import PlantsOverviewMap from "../../components/Overview/Map";
import PlantsOverview from "../../components/Overview/PlantsOverview";
import useCieApi from "../../hooks/useCieApi";
// import { useCieApi } from "../../hooks/useData"
import { useCieWebSocket } from "../../hooks/useCieWebSocket";
import { getStatusDataHashIgnoringWorkerTimestamps } from "../../utils/dataProvider";

const MultiplePlantsOverview = () => {
  const [selectedPlantId, setSelectedPlantId] = useState(0);
  const [expanded, setExpanded] = useState(0);
  const [mapData, setMapData] = useState(null);
  const [dataRevision, setDataRevision] = useState(0);

  const plantsResponse = useCieApi((p) => p.getStatusData(), [dataRevision], {
    hashFunction: getStatusDataHashIgnoringWorkerTimestamps,
    throttleMs: 5000,
  });
  useCieWebSocket((event) => {
    if (plantsResponse.incoming) {
      console.debug(
        "Avoiding websocket re-triggering render.\nReason:data already incoming\nWebsocket Data:",
        event
      );
    } else {
      console.debug(
        "websocket Re-triggering render.\nReason: incoming data\nWebsocket Data:",
        event
      );
      setDataRevision(dataRevision + 1);
    }
  });
  if (!plantsResponse.initialized) {
    return <Loading height="95vh" />;
  }

  if (plantsResponse.error) {
    return <Error title="Plantas no disponibles..." height="95vh" />;
  }

  const plants = plantsResponse.data;

  const props = {
    plants,
    selectedPlantId,
    setExpanded,
    setSelectedPlantId,
    mapData,
    setMapData,
    expanded,
  };
  return <MultiPlantsOverviewView {...props} />;
};

const MultiPlantsOverviewView = ({
  plants,
  selectedPlantId,
  setExpanded,
  setSelectedPlantId,
  mapData,
  setMapData,
  expanded,
}) => {
  return (
    <>
      <Title title="Vista General" />
      <Grid container>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            pr: 1,
          }}
        >
          <Box>
            <PlantsOverviewMap
              plants={plants}
              selectedPlantId={selectedPlantId}
              setExpanded={setExpanded}
              setSelectedPlantId={setSelectedPlantId}
              mapData={mapData}
              setMapData={setMapData}
            />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <PlantsOverview
            plants={plants}
            expanded={expanded}
            setExpanded={setExpanded}
            setSelectedPlantId={setSelectedPlantId}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MultiplePlantsOverview;
