import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import XLSX from "sheetjs-style";

const OUTPUT_ZONE_INFO = {
  id: "Identificador (id)",
  // "zone_number",
  name: "Nombre",
  // "start_id",
  // "end_id",
  threshold: "Umbral de temperatura", // TODO: whats the diff with alarm_threshold?
  // "asset_id",
  // "mapping_start_idx",
  // "mapping_end_idx",
  // "start_offset",
  // "end_offset",
  // "asset_name",
  // "blueprint_name",
  // "layout_id",
  // "alarm_threshold",
};

function buildSummary(zone, dates, granularity, samplingInterval) {
  const zoneData = Object.entries(zone)
    .filter(([key, _]) => Object.keys(OUTPUT_ZONE_INFO).includes(key))
    .map(([key, value]) => ({ Nombre: OUTPUT_ZONE_INFO[key], Valor: value }));

  // .toLocaleDateString("es-cl")
  // .toLocaleTimeString("es-cl")
  const fisrtDate = new Date(Math.min(...dates));
  const lastDate = new Date(Math.max(...dates));
  const data = [
    ...zoneData,
    {
      Nombre: "Fecha inicio",
      Valor: fisrtDate.toLocaleDateString("es-cl"),
    },
    {
      Nombre: "Hora inicio",
      Valor: fisrtDate.toLocaleTimeString("es-cl"),
    },
    {
      Nombre: "Fecha término",
      Valor: lastDate.toLocaleDateString("es-cl"),
    },
    {
      Nombre: "Fecha término",
      Valor: lastDate.toLocaleDateString("es-cl"),
    },
    {
      Nombre: "Granularidad Temporal (minutos)",
      Valor: granularity,
    },
  ];
  if (samplingInterval) {
    data.push({
      Nombre: "Granularidad Espacial (metros)",
      Valor: samplingInterval,
    });
  }
  return data;
}
const DownloadExcelReport = ({
  values,
  fileName,
  zone,
  granularity,
  samplingInterval,
}) => {
  const fileExtension = ".xlsx";

  if (!values) {
    return;
  }

  const idxs = [];
  const dates = [];
  const createDataRow = (entry) => {
    const temperatures = entry?.TEMP_DATA.reduce((obj, tempEntry, index) => {
      const idx = index + 1;
      if (!idxs.includes(idx)) {
        idxs.push(idx);
      }
      return { ...obj, [idx.toString()]: tempEntry.toFixed(1) };
    }, {});

    const date = new Date(entry.TIME_STAMP);
    if (!dates.includes(date)) {
      dates.push(date);
    }
    return {
      Fecha: date.toLocaleDateString("es-cl"),
      Hora: date.toLocaleTimeString("es-cl"),
      ...temperatures,
    };
  };

  const preProcessedData = values.map(createDataRow);
  idxs.sort((a, b) => a - b);
  const download = () => {
    const workbook = XLSX.utils.book_new();

    const tempsSheet = XLSX.utils.json_to_sheet(preProcessedData, {
      header: ["Fecha", "Hora", ...idxs.map((i) => i.toString())],
    });
    XLSX.utils.book_append_sheet(workbook, tempsSheet, "Temperaturas");

    const summarySheet = XLSX.utils.json_to_sheet(
      buildSummary(zone, dates, granularity, samplingInterval)
    );
    XLSX.utils.book_append_sheet(workbook, summarySheet, "Info");

    XLSX.writeFile(workbook, fileName + fileExtension);
  };

  return (
    <Grid container justifyContent={"right"} alignItems={"center"}>
      <Grid item sx={{ width: "300px", py: 2 }}>
        <Button variant={"contained"} color="secondary" onClick={download}>
          Descargar Datos
        </Button>
      </Grid>
    </Grid>
  );
};

export default DownloadExcelReport;
