import spanishMessages from "@blackbox-vision/ra-language-spanish";
import HomeIcon from "@mui/icons-material/Home";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import UpdateIcon from "@mui/icons-material/Update";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { useMemo } from "react";
import { Admin } from "react-admin";
import { AppBar } from "react-admin";
import { CustomRoutes } from "react-admin";
import { Layout } from "react-admin";
import { Menu } from "react-admin";
import { MenuItemLink } from "react-admin";
import { Sidebar } from "react-admin";
import { Route } from "react-router-dom";

import NotificationManager from "./components/Notification";
import AdminDashboard from "./pages/AdminDashboard";
import LoginPage from "./pages/Login/Login";
import MultiplePlantsOverview from "./pages/MultiplePlantsOverview/MultiplePlantsOverview";
import { PasswordReset } from "./pages/Password";
import { PasswordCreate } from "./pages/Password";
import RealTimePlant from "./pages/RealTimePlant/RealTimePlant";
import PlantStatistics from "./pages/Statistics/Statistics";
import { CustomTheme } from "./theme";
import { authProvider } from "./utils/authProvider";
import dataProvider from "./utils/dataProvider";

const messages = {
  es: spanishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "es");

export const CustomSidebar = (props) => {
  return (
    <Sidebar
      sx={{
        "& .RaSidebar-fixed": {
          backgroundColor: "background.dark",
        },
      }}
      {...props}
    />
  );
};

export const CustomMenu = () => {
  return (
    <Menu
      sx={{
        justifyContent: "center",
        "&.RaMenu-closed": {
          opacity: 0.8,
        },
      }}
    >
      <Menu.Item
        to="/"
        leftIcon={<HomeIcon />}
        sx={{
          py: 2,
          "&:hover": {
            backgroundColor: "secondary.main",
          },
          "&:focus": {
            backgroundColor: "secondary.main",
          },
        }}
      />

      <Menu.Item
        to="/realtime"
        leftIcon={<UpdateIcon />}
        sx={{
          py: 2,
          "&:hover": {
            backgroundColor: "secondary.main",
          },
          "&:focus": {
            backgroundColor: "secondary.main",
          },
        }}
      />
      <Menu.Item
        to="/stats"
        leftIcon={<StackedLineChartIcon />}
        sx={{
          py: 2,
          "&:hover": {
            backgroundColor: "secondary.main",
          },
          "&:focus": {
            backgroundColor: "secondary.main",
          },
        }}
      />
    </Menu>
  );
};

export const CustomAppBar = (props) => (
  <AppBar {...props}>
    {/* <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} /> */}
  </AppBar>
);

export const CustomLayout = ({ children, ...props }) => {
  const notificationManager = useMemo(() => {
    console.log("Starting new notification manager", new Date());
    return <NotificationManager />;
  }, []);
  return (
    <Layout
      {...props}
      menu={CustomMenu}
      appBar={CustomAppBar}
      sidebar={CustomSidebar}
    >
      {notificationManager}
      {children}
    </Layout>
  );
};

function App() {
  const admin = (
    <Admin
      requireAuth
      disableTelemetry
      locale="es"
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      loginPage={LoginPage}
      layout={CustomLayout}
      theme={CustomTheme}
      dashboard={AdminDashboard}
      sx={{
        backgroundColor: "primary.main",
      }}
    >
      <CustomRoutes>
        <Route path="stats/:plantId?/:zoneId?" element={<PlantStatistics />} />
        <Route path="realtime/:plantId?/:zoneId?" element={<RealTimePlant />} />
        <Route path="home" element={<MultiplePlantsOverview />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route
          exact
          path="/resetPassword"
          element={<PasswordReset />}
          noLayout
        />

        <Route path="/createPassword" element={<PasswordCreate />} noLayout />
      </CustomRoutes>
    </Admin>
  );

  return admin;
}

export default App;
