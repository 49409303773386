import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import refreshNewClient from "./refreshNewClient";
import reportWebVitals from "./reportWebVitals";
import { AlertContextProvider } from "./utils/AlertProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <AlertContextProvider>
    <App />
  </AlertContextProvider>

  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

refreshNewClient();
