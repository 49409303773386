import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Error = ({ title, text, ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: props.height,
        alignItems: "center",
        flexDirection: "row",
        width: props.width,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ErrorOutlineIcon fontSize="large" color="error" />
        <Typography variant="h4" color="error">
          {title || "Error obteniendo datos"}
        </Typography>
        <Typography variant="h6" color="error">
          {text || "Por favor, intente nuevamente en unos minutos."}
        </Typography>
      </Box>
    </Box>
  );
};

export default Error;
