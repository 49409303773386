import { useState } from "react";

import Error from "../../../components/Error";
import Loading from "../../../components/Loading";
import useCieApi from "../../../hooks/useCieApi";
import EventLog from "../EventLog/EventLog";

function getEventsDataHashIgnoringWorkerTimestamps(events) {
  // TODO: also exclude other fields
  /*
    A single even looks like this:
    {
      "_id": "cUYLCYkBmrwMvurWbnbk",
      "company_id": 1,
      "plant_id": 1,
      "event_type": "Conexión Base de Datos",
      "status": "Not OK",
      "timestamp": "2023-06-29T21:23:50.695303+00:00",
      "worker_timestamp": "2023-07-19T16:47:42.523037+00:00",
      "info": "{\"exception\": \"DB unavailable\"}",
      "visible": false,
      "project_id": null,
      "asset_id": null,
      "_sort": [
        "2023-06-29T21:23:50.695Z"
      ]
    },
  */
  return JSON.stringify(
    events.map(({ worker_timestamp, ...plantData }) => plantData)
  );
}

export const PlantStatisticsDisplay = ({ plantId, dataRevision, fromTime, toTime }) => {
  const [filteredLogTypes, setFilteredLogTypes] = useState(null);
  const [availableLogTypes, setAvailableLogTypes] = useState(null);
  const eventsResponse = useCieApi(
    (dataProvider) => dataProvider.getEventsData(plantId, fromTime, toTime),
    [dataRevision, plantId, fromTime, toTime],
    {
      hashFunction: getEventsDataHashIgnoringWorkerTimestamps,
      throttleMs: 5000,
    }
  );

  if (!eventsResponse.initialized) {
    return <Loading height={"78vh"} />;
  }

  if (eventsResponse.error) {
    return <Error height={"78vh"} />;
  }

  const events = eventsResponse.data;
  return (
      <EventLog
        plantId={plantId}
        events={events} // TODO: fix the at the backend
        fromTime={fromTime}
        toTime={toTime}
        availableLogTypes={availableLogTypes}
        setAvailableLogTypes={setAvailableLogTypes}
        filteredLogTypes={filteredLogTypes}
        setFilteredLogTypes={setFilteredLogTypes}
      />
  );
};
