import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CableIcon from "@mui/icons-material/Cable";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DnsIcon from "@mui/icons-material/Dns";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SpeedIcon from "@mui/icons-material/Speed";
import { blue } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import { amber } from "@mui/material/colors";
import { red } from "@mui/material/colors";
import { Icon } from "leaflet";
import chroma from "chroma-js";


export const REFRESH_S = 15;
export const REFRESH_MS = 1000 * REFRESH_S;

export const backgroundColors = {
  Default: blue[200],
  OK: green[200],
  Warning: amber[200],
  Error: red[100],
};

export const borderColors = {
  Default: blue[700],
  OK: green[700],
  Warning: amber[700],
  Error: red[700],
};

export const statusIcons = {
  OK: <CheckCircleOutlineIcon sx={{ color: "success" }} />,
  Warning: <ErrorOutlineIcon sx={{ color: "warning" }} />,
  Error: <ErrorOutlineIcon sx={{ color: "error" }} />,
  Unavailable: <DoDisturbIcon sx={{ color: "text" }} />,
};

export const stateIcons = {
  Sistema: {
    Default: <DnsIcon sx={{ color: "info" }} />,
    OK: <DnsIcon sx={{ color: "success" }} />,
    Warning: <DnsIcon sx={{ color: "warning" }} />,
    Error: <DnsIcon sx={{ color: "error" }} />,
  },
  Alarma: {
    Default: <NotificationsActiveIcon sx={{ color: "info" }} />,
    OK: <NotificationsActiveIcon sx={{ color: "success" }} />,
    Warning: <NotificationsActiveIcon sx={{ color: "warning" }} />,
    Error: <NotificationsActiveIcon sx={{ color: "error" }} />,
  },
  Fibra: {
    Default: <CableIcon sx={{ color: "info" }} />,
    OK: <CableIcon sx={{ color: "success" }} />,
    Warning: <CableIcon sx={{ color: "warning" }} />,
    Error: <CableIcon sx={{ color: "error" }} />,
  },
};

export const indicatorIcons = {
  Uptime: {
    Default: <SpeedIcon sx={{ color: "info" }} />,
    OK: <SpeedIcon sx={{ color: "success" }} />,
    Warning: <SpeedIcon sx={{ color: "warning" }} />,
    Error: <SpeedIcon sx={{ color: "error" }} />,
  },
  Salud: {
    Default: <FavoriteIcon sx={{ color: "info" }} />,
    OK: <FavoriteIcon sx={{ color: "success" }} />,
    Warning: <FavoriteIcon sx={{ color: "warning" }} />,
    Error: <FavoriteIcon sx={{ color: "error" }} />,
  },
  Tiempo: {
    Default: <AccessTimeIcon sx={{ color: "info" }} />,
    OK: <AccessTimeIcon sx={{ color: "success" }} />,
    Warning: <AccessTimeIcon sx={{ color: "warning" }} />,
    Error: <AccessTimeIcon sx={{ color: "error" }} />,
  },
};

export const indicatorThresholds = {
  Uptime: {
    warning: 95,
    alarm: 90,
  },
  Salud: {
    warning: 90,
    alarm: 80,
  },
  Tiempo: {
    warning: 10,
    alarm: 5,
  },
};

export const projectMapping = {
  2: "Planta de Prueba",
};

export const assetMapping = {
  31: "Activo de Prueba",
};

export const eventMapping = {
  "Conexión Servidor": {
    title: "Servidor (API)",
    color: "#0d47a1",
    status: {
      OK: "Reconexión",
      "Not OK": "Desconexión",
    },
    severity: "info",
  },
  "Conexión Base de Datos": {
    title: "Servidor (DB)",
    status: {
      OK: "Reconexión",
      "Not OK": "Desconexión",
    },
    severity: "info",
    color: "#6200ea",
  },
  "Conexión Fibra": {
    title: "Fibra",
    status: {
      OK: "Reconexión",
      Unavailable: "Desconexión",
    },
    severity: "warning",
    color: "#00bfa5",
  },
  "Corte de Fibra": {
    color: "#bf360c",
    title: "Fibra (Corte)",
    status: {
      OK: "OK",
      "Not OK": "Corte",
    },
    severity: "error",
  },
  "Alarma Activa": {
    title: "Alarma",
    status: {
      OK: "Desactivación",
      "Not OK": "Activación",
    },
    severity: "error",
    color: "#d50000",
  },
  "Conexión Alarma": {
    title: "Alarma",
    status: {
      OK: "Reconexión",
      Unavailable: "Desconexión",
    },
    severity: "warning",
    color: "#00bfa5",
  },
};

export const okMarker = new Icon({
  iconUrl: require("./icons/ok-icon.png"),
  iconSize: [50, 50],
});

const warningMarker = new Icon({
  iconUrl: require("./icons/warning-icon.png"),
  iconSize: [50, 50],
});

const errorMarker = new Icon({
  iconUrl: require("./icons/error-icon.png"),
  iconSize: [75, 75],
});

const unavailableMarker = new Icon({
  iconUrl: require("./icons/unavailable-icon.png"),
  iconSize: [50, 50],
});

export const mapMarkerIcons = {
  OK: okMarker,
  Warning: warningMarker,
  Error: errorMarker,
  Unavailable: unavailableMarker,
};

export const EventTypes = {
  Sistema: [
    "Conexión Base de Datos",
    "Conexión Servidor",
    "Conexión Alarma",
    "Conexión Fibra",
  ],
  Alarma: ["Alarma Activa"],
  Fibra: ["Corte de Fibra"],
};


export const COLORRANGE = chroma.scale(["darkblue", "forestgreen", "gold", "darkred"]).colors(15)