import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useEffect, useState, useRef } from "react";
import { Title } from "react-admin";

import useData from "../../../hooks/useData";
import "../../../styles.css";
import { TraceUpdateContext } from "../../../utils/Ctx";
import Heatmap from "../Heatmap";

const GranularitySelect = ({ granularity, setGranularity }) => {
  const granularityOptions = [
    { value: 5, label: "5min", disabled: false },
    { value: 30, label: "30min", disabled: false },
    { value: 60, label: "1h", disabled: false },
  ];

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <InputLabel>Granularidad</InputLabel>
      <Select
        value={granularity}
        onChange={(event) => setGranularity(event.target.value)}
        label="Granularidad"
      >
        {granularityOptions.map((option) => (
          <MenuItem
            key={option.label}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const AnalysisFilter = (props) => {
  const {
    blueprintSelector,
    zoneSelector,
    setBlueprintSelector,
    setZoneSelector,
    blueprintOptions,
    zoneOptions,
    fromTime,
    setFromTime,
    toTime,
    setToTime,
    granularity,
    setGranularity,
    dateSelectorBlinks,
    ...rest
  } = props;
  return (
    <Grid
      container
      direction="row"
      spacing={1}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        my: 2,
      }}
    >
      <Grid item>
        <FormControl>
          <InputLabel id="zone-id-label">Piso</InputLabel>
          <Select
            labelId="zone-id-label"
            value={blueprintSelector}
            label="Piso"
            onChange={(event) => {
              setBlueprintSelector(JSON.parse(event.target.value));
            }}
          >
            {blueprintOptions}
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl>
          <InputLabel id="zone-id-label">Zona</InputLabel>
          <Select
            labelId="zone-id-label"
            value={zoneSelector}
            label="Zona"
            onChange={(event) => {
              setZoneSelector(JSON.parse(event.target.value));
            }}
          >
            {zoneOptions[blueprintSelector]}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <GranularitySelect
          granularity={granularity}
          setGranularity={setGranularity}
        />
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            inputFormat="DD-MM-YYYY hh:mm "
            label="Desde"
            value={fromTime}
            onChange={(newValue) => {
              setFromTime(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            inputFormat="DD-MM-YYYY hh:mm "
            label="Hasta"
            value={toTime}
            onChange={(newValue) => {
              if (toTime.isBefore(dayjs())) {
                setToTime(dayjs());
              }
              setToTime(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};


const Header = (props) => {
  const {
    plantId,
    fromTime,
    setFromTime,
    toTime,
    setToTime,
    granularity,
    setGranularity,
    blueprintSelector,
    setBlueprintSelector,
    zoneSelector,
    setZoneSelector,
    dateSelectorBlinks,
    zoneLayouts,
  } = props;
  const plantResponse = useData((provider) => provider.getStatusData(plantId));

  if (plantResponse.loading) {
    return <></>;
  }
  if (plantResponse.error) {
    return <></>;
  }
  const plant = plantResponse.data[0];

  const blueprintOptions = Object.keys(zoneLayouts).map((blueprintId) => {
    return (
      <MenuItem key={blueprintId} value={blueprintId}>
        {zoneLayouts[blueprintId][0].blueprint_name}
      </MenuItem>
    );
  });

  function getZones(zoneLayouts, blueprintId) {
    return zoneLayouts[blueprintId].map((zone) => {
      return (
        <MenuItem key={zone.id} value={zone.id}>
          {zone.name}
        </MenuItem>
      );
    });
  }
  let zoneOptions = {};
  for (const blueprintId in zoneLayouts) {
    zoneOptions[blueprintId] = getZones(zoneLayouts, blueprintId);
  }
  const img = plant.logo ? (
    <Grid container
      sx={{
        direction: "row",
        justifyContent: "center",
        alignItems: "center"
      }} >
      <Grid
        item
        justifyContent="center"
        alignItems="center"
        sx={{
          mt: "10px",
        }}
      >
        <Box
          component="img"
          sx={{
            height: 32,
            px: "16px",
          }}
          alt={plant.name}
          src={plant.logo}
        />
      </Grid>
      <span>{plant.name}</span>
    </Grid>
  ) : (
    <span>{plant.name}</span>
  );
  return (
    <>
      <Title title={img} />
      <AnalysisFilter
        blueprintSelector={blueprintSelector}
        zoneSelector={zoneSelector}
        setBlueprintSelector={setBlueprintSelector}
        setZoneSelector={setZoneSelector}
        blueprintOptions={blueprintOptions}
        zoneOptions={zoneOptions}
        fromTime={fromTime}
        setFromTime={setFromTime}
        toTime={toTime}
        setToTime={setToTime}
        granularity={granularity}
        setGranularity={setGranularity}
        dateSelectorBlinks={dateSelectorBlinks}
      />
    </>
  );
};

export const ZoneStatistics = ({
  plantId,
  blueprintSelector,
  setBlueprintSelector,
  fromTime,
  setFromTime,
  toTime,
  setToTime,
  zoneSelector,
  setZoneSelector,
  zones,
  zoneLayouts,
}) => {
  const [granularity, setGranularity] = useState(5);
  const [dateSelectorBlinks, setDateSelectorBlinks] = useState(false);
  const [traceUpdateTimestamp, setTraceUpdateTimestamp] = useState(null);

  return (
    <TraceUpdateContext.Provider
      value={[traceUpdateTimestamp, setTraceUpdateTimestamp]}
    >
      <Stack
        sx={{
          height: "100%",
        }}
      >
        <Header
          plantId={plantId}
          fromTime={fromTime}
          setFromTime={setFromTime}
          toTime={toTime}
          setToTime={setToTime}
          granularity={granularity}
          setGranularity={setGranularity}
          blueprintSelector={blueprintSelector}
          setBlueprintSelector={setBlueprintSelector}
          zoneSelector={zoneSelector}
          setZoneSelector={setZoneSelector}
          dateSelectorBlinks={dateSelectorBlinks}
          zones={zones}
          zoneLayouts={zoneLayouts}
        />
        <StatisticsDisplay
          plantId={plantId}
          fromTime={fromTime}
          setFromTime={setFromTime}
          toTime={toTime}
          granularity={granularity}
          zones={zones}
          zoneSelector={zoneSelector}
          setDateSelectorBlinks={setDateSelectorBlinks}
          dateSelectorBlinks={dateSelectorBlinks}
        />
      </Stack>
    </TraceUpdateContext.Provider>
  );
};

const StatisticsDisplay = ({
  plantId,
  fromTime,
  setFromTime,
  toTime,
  granularity,
  zones,
  zoneSelector,
  dateSelectorBlinks,
  setDateSelectorBlinks,
}) => {
  const [yPos, setYPos] = useState(0);
  const ref = useRef();

  useEffect(() => {
    if (!ref?.current?.offsetTop) {
      return;
    }
    console.warn(
      "YPos",
      ref?.current?.offsetTop,
      window.innerHeight - ref?.current?.offsetTop
    );
    setYPos(ref?.current?.offsetTop);
  }, ref?.current?.offsetTop);

  return (
    <Heatmap
      plantId={plantId}
      zoneId={zoneSelector}
      granularity={granularity}
      fromTime={fromTime}
      setFromTime={setFromTime}
      toTime={toTime}
      height={"100%"}
      dateSelectorBlinks={dateSelectorBlinks}
      setDateSelectorBlinks={setDateSelectorBlinks}
    />
  );
};
