import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useRedirect } from "react-admin";

import { statusIcons } from "../../utils/consts";
import PlantStates from "./PlantStates";

const SinglePlantOverview = ({ plant, expanded, onChange }) => {
  const redirect = useRedirect();
  const handleClick = () => {
    redirect(`/realtime/${plant.id}`);
  };

  const color =
    plant.overall_status === "OK"
      ? "success.main"
      : plant.overall_status === "UNAVAILABLE"
      ? "warning.main"
      : "error.main";
  return (
    <Accordion
      disabled={plant.active !== true}
      onChange={onChange}
      expanded={expanded}
      sx={{
        borderRadius: 1,
        border: 2,
        borderColor: color,
        color: color,
        backgroundColor: "background.light",
        mb: 1,
      }}
    >
      <AccordionSummary
        expandIcon={
          <Button
            sx={{
              ":hover": {
                backgroundColor: color,
                color: "white",
              },
              border: 3,
              borderRadius: 2,
              borderColor: color,
              color: color,
            }}
            onClick={handleClick}
          >
            <Typography variant="body1" fontWeight={"bold"}>
              Ver
            </Typography>
          </Button>
        }
        sx={{
          "& .MuiAccordionSummary-expandIconWrapper": {
            transition: "none",
            "&.Mui-expanded": {
              transform: "none",
            },
          },
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
        >
          {plant.active && statusIcons[plant.overall_status]}
          <Typography color={color} variant="h5">
            {plant.name}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {plant.states.map((state) => {
          return <PlantStates state={state} key={state.name} />;
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default SinglePlantOverview;
