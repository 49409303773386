import Box from "@mui/material/Box";

import "../../../styles.css";
import { MapAndTemps } from "../DoublePlot";

export const RealTimePlantLayout = ({
  blueprintId,
  tempsPerZone,
  zoneIds,
  setZoneIds,
  zones,
  colorscale,
  layoutData,
}) => {
  console.debug(
    "[RealTimePlantLayout] Passing args",
    zoneIds,
    colorscale,
    layoutData
  );
  return (
    <Box
      sx={{
        height: "calc(92vh - 52px)",
        py:3
      }}
    >
      <MapAndTemps
        zones={zones}
        tempsPerZone={tempsPerZone}
        blueprintId={blueprintId}
        zoneIds={zoneIds}
        setZoneIds={setZoneIds}
        colorscale={colorscale}
        {...layoutData}
      />
    </Box>
  );
};
