import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState, forwardRef, useCallback } from "react";

import ArchiveDialog from "./ArchiveDialog";
import EventTypeFilter from "./EventTypeFilter";

const LogHeader = forwardRef(function LogHeader(props, ref) {
  const {
    viewHidden,
    setViewHidden,
    availableLogTypes,
    plantId,
    filteredLogTypes,
    setFilteredLogTypes,
    fromTime,
    toTime,
    events,
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <Grid
      xs={12}
      container
      direction="row"
      spacing={2}
      ref={ref}
      sx={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        py: 1,
      }}
    >
      <Grid xs={12} item>
        <ArchiveDialog
          events={events}
          open={open}
          setOpen={setOpen}
          plantId={plantId}
          fromTime={fromTime}
          toTime={toTime}
        />
      </Grid>
      {/* <Grid xs={12} item>
        <Typography variant="h4" align="center" color="text.dark">
          EVENTOS
        </Typography>
      </Grid> */}
      <Grid item xs={12} lg={8}>
        <EventTypeFilter
          availableLogTypes={availableLogTypes}
          filteredLogTypes={filteredLogTypes}
          setFilteredLogTypes={setFilteredLogTypes}
        />
      </Grid>
      <Grid
        xs={6}
        lg={4}
        container
        direction="column"
        spacing={2}
        item
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item>
          <Button
            sx={{
              minWidth: "120px",
            }}
            variant="contained"
            color={viewHidden ? "success" : "secondary"}
            onClick={(event) => {
              setViewHidden(!viewHidden);
            }}
          >
            Ver Todos
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              minWidth: "120px",
            }}
            variant="contained"
            color="error"
            onClick={() => setOpen(true)}
          >
            Archivar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default LogHeader;
